export default [
  {
    path: '/knowledge-base',
    name: 'knowledgeBase',
    meta: { title: 'knowledgeBase' },
    redirect: { name: 'knowledge-base-root' },
    component: () => import('@/pages/knowledge-base'),
    children: [
      {
        path: 'root',
        name: 'knowledge-base-root',
        meta: { title: 'knowledgeBase' },
        component: () => import('@/pages/knowledge-base/root.vue'),
      },
      {
        path: 'folder/:id',
        name: 'knowledge-base-folder',
        meta: { title: 'knowledgeBase' },
        component: () => import('@/pages/knowledge-base/folder.vue'),
      },
    ],
  },
]