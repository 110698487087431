<template>
  <b-sidebar
    v-model="getValue"
    :width="width"
    right
    no-slide
    no-header
    no-close-on-route-change
    bg-variant="white"
    backdrop-variant="dark"
    backdrop
    v-bind="$attrs"
    v-on:change="$emit('change', $event)">
    <slot></slot>
  </b-sidebar>
</template>
<script>
  export default {
    name: "BSidebarUI",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: 'calc(100% - 280px)',
      },
    },
    computed: {
      getValue: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>

<style scoped>

</style>
