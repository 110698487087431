import Vue from 'vue'
import router from "../router";

import VueGtag from "vue-gtag";

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: {
      id: 'UA-198384950-1',
    },
  }, router)
}
