export default [
  {
    path: "/event/create",
    name: "create-event",
    meta: { title: 'createEvent' },
    component: () => import('@/pages/event/create'),
  },
  {
    path: "/event/:id/edit",
    name: "edit-event",
    meta: { title: 'editEvent' },
    component: () => import('@/pages/event/edit'),
  },
]
