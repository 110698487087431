const state = {
  isSidebar: false,
  isAside: true,
  isAdminLayout: false,
  canLeavePage: true,
  profile: null,
  profileSettings: null,

}

const getters = {
  isSidebar: state => state.isSidebar,
  isAside: state => state.isAside,
  isAdminLayout: state => state.isAdminLayout,
}

const actions = {
}

const mutations = {
  onCloseSidebar () {
    state.isSidebar = false
    state.profile = null
    state.profileSettings = null
  },
  setProfile (state, payload) {
    state.profile = payload
    state.profileSettings = null
    state.isSidebar = !!payload
  },
  setProfileSettings (state, payload) {
    state.profileSettings = payload
    state.profile = null
    state.isSidebar = !!payload
  },
  setCanLeavePage (state, payload) {
    state.canLeavePage = payload
  },
  setAside (state, payload) {
    state.isAside = payload
  },
  setAdminLayout (state, payload) {
    state.isAdminLayout = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
