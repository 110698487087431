import Vue from "vue"
import i18n from './../plugins/vue-i18n';

Vue.filter('centsToDollars', num => {
  if (num) {
    const dollars = num / 100;
    const currencyOptions = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).resolvedOptions();

    const value = (dollars).toLocaleString('en-US', {
      ...currencyOptions,
      style: 'decimal',
    });
    return value
  } else return num
})

Vue.filter('formatBytes', (bytes, decimals = 2) => {
  if (bytes === 0) return '0 ' + i18n.t('base.sizes')[0]
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = i18n.t('base.sizes')

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})
