import news from "./news";

export default [
  {
    path: "/profile/:id/",
    name: "viewProfile",
    meta: { title: 'viewProfile' },
    component: () => import('@/pages/profile/id'),
  },
  {
    path: "/profile/:id/settings/",
    name: "viewSettingsProfile",
    meta: { title: 'viewProfile' },
    component: () => import('@/pages/profile/id/settings'),
  },
]
