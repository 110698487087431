const state = {
  error: {
    state: false,
    message: '',
  },
  progressData: null,
}

const getters = {
  isError: state => state.error.state,
  errorMessage: state => state.error.message,
  progressData: state => state.progressData
}

const mutations = {
  setError (state, payload) {
    state.error = payload
  },
  setProgressData (state, payload) {
    state.progressData = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
