export default [
  {
    path: "/calendar",
    name: "calendar",
    meta: { title: 'events' },
    redirect: { name: 'calendar-events' },
    component: () => import('@/pages/calendar'),
    children: [
      {
        path: 'events',
        name: 'calendar-events',
        meta: { title: 'events' },
        component: () => import('@/pages/calendar/events.vue'),
      },
      {
        path: 'birthday',
        name: 'calendar-birthday',
        meta: { title: 'events' },
        component: () => import('@/pages/calendar/birthday.vue'),
      },
    ],
  },
]
