export default [
  {
    path: '/news',
    name: 'indexNews',
    meta: { title: 'indexNews' },
    component: () => import('@/pages/news'),
  },
  {
    path: "/news/create",
    name: "createNews",
    meta: { title: 'createNews' },
    component: () => import('@/pages/news/create'),
  },
  {
    path: "/news/:id",
    name: "viewNews",
    meta: { title: 'viewNews' },
    component: () => import('@/pages/news/id'),
  },
  {
    path: "/news/:id/edit",
    name: "editNews",
    meta: { title: 'editNews' },
    component: () => import('@/pages/news/id/edit'),
  },
]
