import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from './services/api.service';
import i18n from './plugins/vue-i18n'
Vue.config.productionTip = false
import './helpers/filters'
import './plugins'

// API service init
ApiService.init();
Vue.http = Vue.prototype.$http = ApiService

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
