import news from "./news";
import pages from "./pages";
import profile from "./profile";
import calendar from "./calendar";
import search from "./search";
import knowledgeBase from "./knowledgeBase";
import event from "./event";
import businessTrips from "./businessTrips"

const routers = [
  {
    path: '/',
    redirect: '/news',
    component: () => import('@/layouts/LayoutUser.vue'),
    children: [
      ...news,
      ...profile,
      ...calendar,
      ...search,
      ...knowledgeBase,
      ...pages,
      ...event,
      ...businessTrips,
    ],
  },
]
export default routers
