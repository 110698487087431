export default [
  {
    path: '/admin/banner',
    name: 'indexBannerAdmin',
    meta: { title: 'indexBannerAdmin' },
    component: () => import('@/pages/admin/banner'),
  },
  {
    path: "/admin/banner/create",
    name: "createBannerAdmin",
    meta: { title: 'createBannerAdmin' },
    component: () => import('@/pages/admin/banner/create'),
  },
  {
    path: "/admin/banner/:id/edit",
    name: "editBannerAdmin",
    meta: { title: 'editBannerAdmin' },
    component: () => import('@/pages/admin/banner/id/edit'),
  },
]
