export default [
  {
    path: '/',
    component: () => import(/* webpackPrefetch: true */ '@/pages/auth/Auth'),
    children: [
      {
        name: 'login',
        path: '/login',
        meta: { title: 'login' },
        component: () => import(/* webpackPrefetch: true */ '@/pages/auth/Login'),
      },
    ],
  },
]
