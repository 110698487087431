import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import i18n from './plugins/vue-i18n';
import adminRouters from './routers/admin';
import portalRouters from './routers/portal';
import auth from "./routers/auth"
import policy from "./routers/policy"

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/default.vue'),
      children: [
        ...adminRouters,
        ...portalRouters,
      ],
    },
    ...auth,
    ...policy,
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      meta: { title: '404' },
      component: () => import('@/pages/error/404.vue'),
    },
    {
      // the 404 route, when none of the above matches
      path: '/403',
      name: '403',
      meta: { title: '403' },
      component: () => import('@/pages/error/403.vue'),
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch('auth/onVerifyAuth')]).then(() => {
    next()
  })
  // set page tab name
  document.title = i18n.t(`page.${typeof to.meta === 'function' ? to.meta(to).title : to.meta.title}`)
});

export default router
