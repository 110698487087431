
const state = {
  currentFolder: null,
  folderSubtitle: '',
}

const getters = {
  folderSubtitle: (state) => state.folderSubtitle
}
const actions = {}

const mutations = {
  setCurrentFolder (state, payload) {
    state.currentFolder = payload
  },
  setFolderSubtitle: (state, payload) => state.folderSubtitle = payload
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
