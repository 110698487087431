import Vue from 'vue'

const Toast = {}

const STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
}

Toast.install = function (Vue, defaultOptions) {
    Vue.prototype.$toast = function (message, type = STATUS.SUCCESS, options = {}) {
        defaultOptions.toastClass += ` custom-toast--${type}`
        return this.$bvToast.toast(message, { ...defaultOptions, ...options })
    }
}

Vue.use(Toast, {
    toastClass: 'custom-toast',
    headerClass: 'custom-toast-header',
    bodyClass: 'custom-toast-body',
    solid: true,
})

