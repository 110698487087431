'use strict'
import qs from 'qs'
function convertBooleanToInt (prefix, value) {
  if (typeof value === 'boolean') {
    return +value
  }
  return value
}
export function paramsSerializerQs (params) {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    filter: convertBooleanToInt,
  })
}
export function paramsSerializer (params) {
  return JSON.stringify(params, {
    filter: convertBooleanToInt,
  })
}

export function prepareParams (params) {
  const obj = {}
  if (
    typeof params === 'object' &&
    !Array.isArray(params) &&
    params !== null
  ) {
    for (const propName in params) {
      if (params.hasOwnProperty(propName)) {
        if (
          typeof params[propName] === 'string' &&
          params[propName].length === 0
        ) {
          continue
        }
        obj[propName] = params[propName]
      }
    }
    return obj
  } else {
    return params
  }
}

export function prepareData (inputData, skipKeys = []) {
  const rawData = JSON.parse(JSON.stringify(inputData))

  const data = {}

  for (let key in rawData) {
    if (skipKeys.includes(key) || key === 'files') continue
    if (Array.isArray(rawData[key])) {
      data[key] = rawData[key].map((i) => {
        if (typeof i === 'object' && i !== null) return i.uuid
        return i
      })
      continue
    }
    if (typeof rawData[key] === 'object' && rawData[key] !== null) {
      data[key] = rawData[key].uuid
      continue
    }
    data[key] = rawData[key]
  }
  return data
}
