export const locale = {
    base: {
        general: 'General',
        aprove: 'Approve',
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        toSubscriptions: 'To subscriptions',
        subscribtions: 'Subscribtions',
        colleagues: 'Colleagues',
        search: 'Search',
        searchMain: 'Search for news, employees, documents, events',
        emptyList: 'Empty list',
        publish: 'Publish',
        unlock: 'Unlock',
        published: 'Publish',
        rejected: 'Reject',
        send: 'Send',
        saveAsDraft: 'Save to drafts',
        save: 'Save',
        select: 'Select',
        noData: 'No information',
        toMain: 'To the main',
        myArticles: 'My publications',
        myDrafts: 'My drafts',
        exit: 'Log out',
        edit: 'Edit',
        delete: 'Delete',
        close: 'Close',
        leave: 'Leave',
        more: 'More',
        add: 'Add',
        showMore: 'Show more',
        hideMore: 'Hide',
        answer: '0 answers | {n} answer | {n} answers | {n} answers',
        category: '0 categories | {n} category | {n} categories | {n} categories',
        clustery: '0 regions | {n} regions | {n} regions | {n} regions',
        categories: 'Categories',
        clusters: 'Regions',
        clusterOne: 'Region',
        categoryOne: 'Category',
        answered: 'Replied',
        upload: 'Upload',
        uploadFile: 'Upload file',
        uploadImg: 'Loading an image',
        supportedFormats: 'Acceptable formats: {value}.',
        supportedSize: 'Resolution: {value}.',
        fileUploaded: 'File uploaded',
        yes: 'Yes',
        no: 'No',
        maybe: 'Maybe',
        join: 'Join',
        tune: 'Tune',
        result: 'Result',
        congratulate: 'Congratulate',
        congratulation: 'Congratulation',
        actions: 'Actions',
        commentOrigin: 'Comment',
        writeComment: 'Write a comment...',
        department: 'department',
        create: 'Create',
        articleModerationEnabled: 'News moderation',
        cancel: 'Cancel',
        comment: '0 Comments | {n} Comment | {n} Comments | {n} Comments',
        seeComments: 'View more comments',
        reply: 'Reply',
        myProfile: 'My profile',
        profileSetting: 'Profile setting',
        companyStructure: 'Company structure',
        businessTrips: 'Business trips',
        portal: 'Portal',
        adminPanel: 'Admin panel',
        never: 'Never',
        date: 'Date',
        time: 'Time',
        after: 'After',
        liked: 'Liked',
        selectDate: 'Select date',
        times: 'time(s)',
        subTitleDocuments: 'Subtitle for documents',
        subTitle: 'subTitle',
        sizes: ['Byte', 'Kb', 'Mb', 'Gb'],
        cluster: 'Region',
        timezoneInfo: 'Time is displayed in Europe GMT +3:00',
        saveData: 'Data saved successfully',
        icons: 'Icons',
        share: 'Share',
        enterName: 'Enter the title',
        sum: 'Sum',
        currency: 'Currency',
        rate: 'Rate',
        noItemsByParams: 'No results were found for the specified parameters.',
        deleteNotAvailable: 'It is impossible to delete - the element is already in use.',
        byDefault: 'Default',
        others: 'Other',
        ok: 'Good',
        filter: 'Filter',
        filters: 'Filters',
        apply: 'Apply',
        clear: 'Clear',
        enterLocation: 'Enter a location'
    },
    subscribe: {
        successNewsTitle: 'You have subscribed to news {name}',
        successNewsDescription: 'Notifications about new user {name} posts will be sent to your email.',
    },
    lang: {
        ru: 'Russian',
        en: 'English',
        ka: 'Georgian',
        ua: 'Ukrainian',
    },
    actions: {
        create: 'Creating',
        remove: 'Deleting',
        update: 'Editing',
    },
    settings: {
        articleModerationEnabled: {
            true: 'Active',
            false: 'Inactive',
        },
    },
    statuses: {
        allStatuses: 'All publications',
        published: 'Published',
        rejected: 'Rejected',
        draft: 'Drafts',
        moderation: 'Moderation',
    },
    roles: {
        ROLE_SUPER_ADMIN: 'Super Administrator',
        ROLE_ADMIN: 'Administrator',
        ROLE_LDAP_USER: 'employee',
    },
    businessRoles: {
        ROLE_ADMIN: 'Administrator',
        super_admin: 'Super Administrator',
        budget_admin: 'Budget Administrator',
        budget_owner: 'Owner of budgets',
        alternate: 'Deputy Budget Owner',
        sub_budget_owner: 'Subbudget owner',
        hr_director: 'HR Director',
        administrative_manager: 'Administrative manager',
        accountant: 'Accountant',
        hr: 'Human Resources Department',
        employee: 'Employee',
        all: 'All roles',
    },
    socialNetworks: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        twitter: 'Twitter',
        skype: 'Skype',
        telegram: 'Telegram',
        vk: 'Vkontakte',
        ok: 'Odnoklassniki',
        strava: 'Strava',
    },
    leavePage: {
        question: 'Want to leave the page?',
        info: 'All your changes will be lost.',
    },
    page: {
        login: 'Login',
        indexNews: 'News',
        viewNews: 'News',
        createNews: 'Creating news',
        createBusinessTrip: 'Creating a business trip',
        editNews: 'Editing news',
        indexNewsAdmin: 'News',
        indexTopicAdmin: 'News categories',
        viewTopicAdmin: 'Category',
        createTopicAdmin: 'Creating category',
        editTopicAdmin: 'Editing category',
        viewProfile: 'Profile',
        indexLogsAdmin: 'Logging',
        indexBannerAdmin: 'Banner',
        createBannerAdmin: 'Banner creation',
        editBannerAdmin: 'Editing a banner',
        indexQuizAdmin: 'Quizzes',
        indexUsersAdmin: 'Users',
        indexSettingsAdmin: 'Settings',
        knowledgeBase: 'Documents',
        indexPagesAdmin: 'Pages',
        createPageAdmin: 'Creating a page',
        editPageAdmin: 'Editing a page',
        indexRegionAdmin: 'Regions',
        indexDictionariesAdmin: 'Dictionaries',
        indexSubmittingBudgets: 'Submitting budgets',
        greetingAdmin: 'Greeting',
        events: 'Events',
        createEvent: 'Create an event ',
        editEvent: 'Edit event',
        editBusinessTrip: 'Editing an application',
        indexSearch: 'Search',
        'about-company': 'About company',
        'about-products': 'About products',
        'company-structure': 'Company structure',
        collaboration: 'Collaboration',
        welcome: 'Welcome!',
        404: '404',
        policy: 'Terms of use of the portal',
        viewBusinessTrips: 'Business trips',
    },
    subscribtions: {
        noUsers: 'You are not currently subscribed to your colleagues.',
    },
    error: {
        404: {
            title: 'Page not found :(',
            desc: 'It may have been deleted and changed the address',
            btn: 'Go to Main page',
        },
    },
    modal: {
        areYouSureToDelete: 'Are you sure you want to delete?',
        deletingTextModal: 'Deleting a Region',
        areLongTime: 'The process can take a long time...',
    },
    indexNews: {
        new: 'News',
        likedNew: 'Not liked | Liked {n} person | Liked {n} people | Liked {n} people',
    },
    greeting: {
        show: 'Do not show greeting',
        create: 'Create a greeting',
        edit: 'Edit a greeting',
        preview: 'Preview',
        form: {
            image: {
                label: 'Download the cover of the greeting',
            },
            text: {
                label: 'Greeting text',
                placeholder: 'Enter your greeting',
            },
        },
    },
    createNews: {
        titleNew: 'Creating news',
        titleEdit: 'Editing news',
        preview: 'News preview',
        form: {
            title: 'Title',
            subTitle: 'Subtitle',
            topic: 'News category',
            image: 'Image',
            body: 'Content',
            commentable: 'Allow to comment news',
            refuseReason: 'Reason',
            slug: 'Slug',
        },
        placeholder: {
            title: 'Enter title',
            subTitle: 'Enter subtitle',
            titleEn: 'Enter a title in your local language',
            text: 'Enter the main text',
            topic: 'Select a category',
            refuseReason: 'Enter the reason',
            slug: 'Enter slug',
        },
        uploadImageTitle: 'Upload a news cover',
        uploadImageDesc: 'Acceptable formats: JPEG, PNG. Maximum size: 8 МБ.<br/>Minimum resolution: {size}.',
        refuseReason: 'Refuse reason',
    },
    viewNews: {
        isModerate: 'News is being moderated',
        shareNews: 'Share news with colleagues',
        newsSent: 'News sent',
        statuses: {
            rejected: 'The news has not passed moderation',
            rejectedDesc: 'Your news has not been moderated. Contact to administrator.',
            rejectedSuccess: 'News successfully rejected',
            draft: 'News in drafts',
            draftDesc: 'Your news is not published.',
            moderation: 'News on moderation',
            moderationDesc: 'Your news has been added to the moderation queue. It will appear on the portal if one of the moderators approve it.',
            published: 'News published',
            publishedDesc: 'Your news has passed moderation and published on the portal.',
            waitModeration: 'After moderation it will be published on the portal.',
            savedToDrafts: 'News saved to drafts',
            offModeration: 'Turn off moderation mode?',
            offModerationDesc: 'All news awaiting moderation will be automatically published.',
        },
    },
    indexNewsAdmin: {
        name: 'Name',
        author: 'Author',
        status: 'Status',
    },
    indexTopicAdmin: {
        new: 'Category',
        name: 'Name',
        shared: 'Available for publication',
    },
    indexRegionAdmin: {
        new: 'Region',
        name: 'Name',
        regionExists: 'This name of the region already exists',
    },
    indexDictionariesAdmin: {
        tripPurposes: 'Purpose of the trip',
        currencies: 'Currencies',
        transportTypes: 'Transport types',
        ticketClasses: 'Ticket class',
        services: 'Services',
        currencyCode: 'Currencies',
        availableForPublish: 'Available for publication',
        availableForSelection: 'Available for selection',
    },
    indexSubmittingBudgets: {
        description: 'Enter budgets / sub-budgets, exchange rates and daily allowance',
        budgets: 'Budgets',
        reports: 'Reports',
        currencyRates: 'Exchange Rates',
        dailyAllowance: 'Daily allowance',
    },
    indexUsersAdmin: {
        name: 'Name',
        role: 'Role',
        roles: 'Roles',
        department: 'Department',
        alternates: 'Deputy',
        alternate: 'Deputy Budget Owner',
        subBudgets: 'Subbudget owners',
        subBudget: 'Subbudget owner',
        previewText: 'Assign active roles by key users',
        roleAlreadyExist: 'Role already assigned {value}',
        budgetOwnersAlternate: 'Deputy budget owner',
        budgetOwnersSubBudgetOwner: 'Subbudget owner at budget owner',
    },
    indexLogsAdmin: {
        date: 'Date, time',
        entity: 'Essence',
        name: 'Name',
        action: 'Action',
        author: 'Author',
        businessTripName: 'Title/Number of application',
    },
    indexSettingsAdmin: {
        name: 'Name',
        status: 'Status',
    },
    indexBannerAdmin: {
        name: 'Name',
        status: 'Status',
        statuses: {
            active: 'Active',
            notActive: 'Inactive',
        },
        new: 'Banner',
    },
    indexQuizAdmin: {
        new: 'Опрос',
        dateAndTime: 'Date, time',
        question: 'Question',
        status: 'Status',
    },
    createQuizAdmin: {
        creating: 'Creating quiz',
        editing: 'Editing quiz',
        form: {
            question: 'Question',
            answer: 'Answer option {number}',
            anonymous: 'Anonymous quiz',
        },
        placeholder: {
            question: 'Ask a question',
            answer: 'Type answer',
        },
    },
    createBannerAdmin: {
        preview: 'Banner preview',
        titleNew: 'Creating banner',
        titleEdit: 'Editing banner',
        titleCrop: 'Cover loading',
        uploadImageTitle: 'Upload your banner cover or banner',
        uploadImageDesc: 'Acceptable formats: JPEG, PNG. Maximum size: 8 МБ. Minimum resolution: 800х100.',
        form: {
            textBanner: 'Banner text',
            textColor: 'Text color',
            textColorBanner: 'Banner text color',
            textBtnColor: 'Button text color',
            btnColor: 'Button color',
            textBtn: 'Button text',
            link: 'Link for button',
            startDate: 'Start of publication',
            timeStart: 'Time',
            endDate: 'End of publication',
            timeEnd: 'Time',
        },
        placeholder: {
            textBanner: 'Enter the main text of the banner',
            textBtn: 'Enter the button text',
            link: 'Insert a link',
            startDate: 'Data',
            timeStart: 'Time',
            endDate: 'Data',
            timeEnd: 'Time',
        },
        danger: {
            startDate: 'Date of publish',
            endDate: 'The end date of the publication must be after the start date',
        },
        activeMessage: 'Only one banner can be active',
    },
    createTopicAdmin: {
        creating: 'Creating category',
        editing: 'Editing category',
        form: {
            name: 'Name of category',
            shared: 'Available for publication',
        },
        placeholder: {
            name: 'Enter category name',
        },
    },
    createRegionAdmin: {
        creating: 'Creating region',
        editing: 'Editing region',
        form: {
            name: 'Name of region',
            rawCountries: 'Countries',
        },
        placeholder: {
            name: 'Enter region name',
        },
    },
    editPageAdmin: {
        title: 'Edit page',
        infoIcon: 'Upload an icon for a folder',
        subtitle: 'Local language subtitle',
        subtitleEn: 'Subtitle in English',
        localTitle: 'Local language title',
        englishTitle: 'Title in English',
    },
    createDictionariesAdmin: {
        title: {
            trip_purpose: 'Create a travel purpose',
            currencies: 'Currency creation',
            transport: 'Create a transport type',
            ticket: 'Creating a ticket class',
            service: 'Service creation',
        },
        label: {
            trip_purpose: 'Purpose of the trip',
            currencies: 'Currency',
            transport: 'Transport type',
            ticket: 'Ticket class',
            service: 'Service',
        },
        placeholder: 'Enter the title',
        child: {
            transport: 'Ticket class',
        },
    },
    editDictionariesAdmin: {
        title: {
            trip_purpose: 'Editing a travel destination',
            currencies: 'Editing Currency',
            transport: 'Transport type editing',
            ticket: 'Editing a ticket class',
            service: 'Editing a service',
        },
    },
    createBudgetAdmin: {
        title: 'Create a budget',
        subBudgetTitle: 'Create a sub-budget',
        budget: 'Budget',
        subBudget: 'Subbudget',
        budgetOwner: 'Budget owner',
        subBudgetOwner: 'Subbudget owner',
        budgetSubbudgetName: 'Budget / Sub-budget name',
        budgetName: 'Budget name',
        subBudgetName: 'Subbudget name',
        defaultBudget: 'Default budget',
        defaultSubBudget: 'Default subbudget',
        placeholder: {
            user: 'Choose a budget owner',
            subBudgetOwner: 'Choose the owner of the subbudget',
            name: 'Enter the name of the budget',
            subName: 'Enter the name of the sub-budget',
            year: 'Choose a year',
        },
        createBudget: 'Create a budget',
        createSubBudget: 'Create subbudget',
        empty: 'No budget has been created yet',
        emptyByParams: 'No results were found for the specified parameters.',
    },
    editBudgetAdmin: {
        title: 'Editing a budget',
        subBudgetTitle: 'Editing a sub-budget',
    },
    createCurrencyRate: {
        currencyRate: 'Exchange Rates',
        addRate: 'Add course',
        placeholders: {
            date: 'Choose a date',
        },
        empty: 'Currency exchange rate not yet created',
    },
    editDailyAllowance: {
        dailyAllowance: 'Daily allowance',
        dailyAllowanceSize: 'Daily allowance',
        travelDirection: 'Direction of travel',
        travelDirectionPlaceholder: 'Enter direction of travel',
        create: 'Create daily allowance',
        creating: 'Create daily allowance',
        editing: 'Edit daily allowance',
    },
    budgetReport: {
        total: 'Total',
        used: 'Used',
        remaining: 'Remainder',
        owners: 'Budget / Sub-budget Owner',
        budget: 'Budget',
        sub_budget: 'Subbudget',
        noBudgets: 'No budget has been created yet',
        allDepartments: 'All departments',
        corporate: 'Corporate',
        finance: 'Finance',
        spent: 'Spent',
        amountPending: 'Amount ($) pending',
    },
    businessTrips: {
        faq: 'Guide for the {link} functionality',
        faqText: '«Business trips»',
        transfer: 'Transfer',
        route: 'Route',
        created: 'Created',
        title: 'Business trips',
        allTrips: 'All applications',
        daysCount: 'Number of days',
        myTrips: 'My applications',
        trip: 'Application',
        trips: 'Applications',
        approved: 'Approved',
        inProcessing: 'In processing',
        completed: 'Completed',
        budgets: 'Budgets',
        reportsByEmployee: 'Employee reports',
        number: 'Number',
        status: 'Status',
        employeeOrNot: 'Employee / Consultant',
        tripPurpose: 'Purpose of the trip',
        destination: 'Destination',
        startDateToEndDate: 'Start / end date',
        plan: 'Plan',
        fact: 'Fact',
        planSum: 'Sum ({currencySymbol}) plan',
        factSum: 'Sum ({currencySymbol}) fact',
        filters: {
            startDate: 'Start date',
            endDate: 'End date',
            createdDate: 'Date of creation',
        },
        deleteReason: 'Reason for deletion',
        roleStatuses: {
            approved: 'Application approved',
            pending: 'Application for approval',
            rejected: 'Application rejected',
        },
        factSpent: 'Actual costs',
        pinnedEmployees: 'Assigned employees',
        viewFrom: 'You are viewing the page from ',
        fromAllBudgetOwners: 'All budget owners',
        inputAccountantExpensesReason: 'Enter the reason for the excess of actual costs',
    },
    createBusinessTrip: {
        title: 'Create a business trip',
        employee: 'Employee',
        notEmployee: 'Consultant',
        fio: 'Full name',
        startBusinessTrip: 'Start of business trip',
        endBusinessTrip: 'End of business trip',
        dayCounts: 'Number of days: {count}',
        additional: 'Additionally',
        transferAirport: 'Transfer from / to the airport',
        transferOffice: 'Transfer to / from the office or other point',
        datePlaceTime: 'Date, place and time',
        transportType: 'Transport type',
        cityAndCountry: 'City, country',
        country: 'Country',
        city: 'Town',
        placeholder: {
            fio: 'Enter your full name',
            user: 'Choose an employee',
            transportType: 'Select the type of transport',
            ticketType: 'Select ticket class',
            additional: 'Select additional services',
            transferAirport: 'Specify the date, place and time',
            otherInfo: 'Travel wishes',
        },
        errors: {
            cantCreateTrip: 'Unable to create a business trip',
            notFoundRoleAndBudgetCount: 'No {roles} have been assigned to your business unit and no budget has been allocated. Please contact your manager.',
            not_found_role: 'Your business unit has not been assigned {roles}. Please contact your manager.',
            budget_count: 'No budget has been allocated for your business unit. Please contact your manager..',
        },
        empty: 'You haven\'t had any business trips yet',
        emptyDesc: 'Create a business trip request',
        arrange: 'Checkout',
        successGoToApprove: 'Application {number} has been sent for processing.',
        successGoToReApprove: 'Application {number} has been sent for re-approval.',
        successClosed: 'Application {number} has been closed.'
    },
    editBusinessTrip: {
        title: 'Editing a business trip',
        request: 'Application {number}',
        budgetOwnerForApprove: 'Budget / Sub-budget Owner',
        budget: 'Budget / Sub-budget Name',
        hotelPrice: 'Cost of living',
        transportPrice: 'Transport cost',
        transferPrice: 'Transfer cost',
        dailyPayment: 'Daily allowance',
        otherExpenses: 'Other',
        otherExpensesDestination: 'Assignment of the amount "Other"',
        total: 'Total',
        placeholder: {
            budgetOwnerForApprove: 'Choose from the list',
            budget: 'Choose from the list',
        },
        reApproval: 'Reapproval',
        reApprovalDesc: 'Submit a request for reapproval?',
        wishes: 'Wishes',
        accommodation: 'Accommodation',
        transport: 'Transport',
        transfer: 'Transfer',
        uploadDocumentsTickets: 'Upload documents, tickets'
    },
    businessTripStatuses: {
        created: 'In processing',
        wait_approval: 'Pending approval',
        approved: 'Approved',
        partially_approved: 'Partially approved',
        completed: 'Issued',
        closed: 'Closed',
        decline: 'Rejected',
    },
    profile: {
        information: 'Information',
        articlesMy: 'My publications',
        articles: 'Publications',
        birthday: 'Birth date',
        birthdaysModal: 'Birthdays',
        country: 'Country',
        city: 'city',
        businessUnit: 'Business unit',
        company: 'Company',
        socialProfiles: 'Social profiles',
        contactInfo: 'Contact Information',
        phone: 'Phone number',
        mail: 'Email',
        workAddress: 'Work address',
        career: 'Career',
        joinedToTeam: 'Joined the team IDS BORJOMI',
        leader: 'Head',
        personalInfo: 'Personal information',
        language: 'Languages',
        certificationsAndSkills: 'Certifications and additional skills',
        workExperience: 'Work experience before IDS BORJOMI',
        hobbies: 'Hobbies',
        functional: 'Functional',
        removedText: 'This employee is not currently employed by the company',
    },
    profileSettings: {
        title: 'Profile settings',
        mainInformation: 'Main information',
        socialProfiles: 'Social profiles',
        alerts: 'Notifications',
        upload: 'Upload a photo',
        receiveAlerts: 'Receive mailbox notifications',
        form: {
            avatar: 'Photo',
            workAddress: 'Work address',
            languages: 'Languages',
            additionalSkills: 'Certifications and additional skills',
            prevExperience: 'Work experience before IDS BORJOMI',
            hobbies: 'Hobbies',
            social: 'Social net',
            link: 'Link',
            functional: 'Functional',
            locale: 'Portal language',
            receivePublicationMessage: 'Answers to my posts',
            receiveBirthdayMessage: 'Happy Birthday',
        },
        placeholder: {
            workAddress: 'Enter the address',
            languages: 'Languages',
            additionalSkills: 'Write about your certifications and skills',
            prevExperience: 'Work experience',
            hobbies: 'Write about your hobbies',
            social: 'Select social net',
            link: 'Insert a link',
            functional: 'Describe what responsibilities do you have',
        },
    },
    staffSearch: {
        name: 'Name',
        phone: 'Phone number',
        email: 'Email',
    },
    search: {
        title: 'Search results',
        desc: 'On request <span class="font-weight-bold">{search}</span> founded <span class="font-weight-bold">{count}</span> results',
        articles: 'News',
        staff: 'Employees',
        medias: 'Documents',
        events: 'Events',
        empty: {
            title: 'Search returned no results',
            desc: 'Try using other keywords',
        },
    },
    auth: {
        auth: 'Login',
        authDesc: 'Fill in the fields below, to enter the portal.',
        authConfirm: 'Check your mail',
        authConfirmDesc: 'We have just sent a six-digit code to the address <span class="font-weight-boldest">{email}</span>. Leave this window open while checking your inbox and then enter the code below.',
        form: {
            email: 'Email',
            password: 'Password',
            code: 'Code',
        },
        iAccept: 'I accept',
        termsOfUseOfThePortal: 'rules for using the portal',
        signIn: 'Sign in',
        didtReceiveLetter: 'Didn\'t receive a letter?',
        sendCodeAgain: 'Send code again',
        codeSent: 'Your code has been sent again.',
        error: {
            invalidCredentials: 'Login or password entered incorrectly',
            blockAccaount: 'Your account has been blocked. To restore access, contact the admin',
            accountUnlock: 'User unlocked',
            invalidCode: 'You entered an incorrect code. Try again.',
        },
    },
    validation: {
        invalid: '{name} is not valid',
        required: '{name} is required field',
        requiredIf: '{name} is required field',
        greaterThan: 'There must be more {value}',
        lessThan: 'Maximum {value}',
        requiredDays: 'Select days to set recurrence',
        alphaNum: '{name} must be in English and doesn\'t have spaces',
        alphaNumCustom: '{name} must be in English and doesn\'t have spaces',
        variant: 'Is required field',
        url: '{name} must be URL address',
        imageFormat: 'Invalid file format. Acceptable formats: {format}',
        imageWeight: 'The file is too large. Maximum size: {weight} MB',
        imageSize: 'Invalid file resolution. Minimum Resolution: {size}',
        imageMaxCount: 'Maximum number of uploaded files - {count}',
        fileNameMaxCount: 'The maximum number of characters in a file name is {count}',
        fileMinWeight: 'Minimum file size: {size} KB',
        someFileAlreadyExists: 'One of the files already exists on the system',
        isAfter: '{name} must be after the start date',
        maxLength: 'The maximum number of characters is {count}',
        maxValue: 'Maximum value - {val}',
        fileType: 'Downloaded file format  not supported by the system',
    },
    quiz: {
        quiz: 'Quiz',
        startQuiz: 'Take a quiz',
        selected: 'The answer is chosen',
        results: 'Quiz results',
        publicResults: 'Results',
        voting: 'Voting',
        variant: 'One of the options',
        activeMessage: 'Only one quiz can be active',
        thankYou: 'Thanks for taking the survey',
        statuses: {
            active: 'Active',
            notActive: 'Inactive',
        },
        peopleVote: 'Voted 0 person | Voted {n} person | Voted {n} people | Voted {n} people',
        peopleSelected: 'Chosen 0 person | Chosen {n} person | Chosen {n} people | Chosen {n} people',
    },
    calendar: {
        toEvents: 'To events',
        eventText: 'Event',
        createEvent: 'Create an event ',
        editEvent: 'Edit event',
        removeEvent: 'Confirm deletion',
        removeEventAlert: 'Event deleted',
        birthdays: 'Birthdays',
        units: 'Business units',
        year: 'Year',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        today: 'Today',
        none: 'No repeat',
        typeEvent: 'Event',
        typeInfo: 'Informative',
        showAllUsers: 'Show all participants',
        participating: 'Do you participate?',
        wantToJoin: 'Would you like to participate?',
        successJoinMessage: 'Join request has been sent to the organizer',
        allParticipants: 'All participants of the event',
        participant: '0 participants | {n} participant | Chosen {n} participants | Chosen {n} participants',
        // birthdayMan: '0 birthday people | {n} birthday person | Chosen {n} birthday people | Chosen {n} birthday people',
        birthdayMan: '0 birthday people | {n} birthday person | {n} birthday people | {n} birthday people',
        inputCongratulation: 'Enter congratulation',
        repeatAt: 'Repeat every',
        onSuchDays: 'on such days:',
        congratulationWasSend: 'Congratulation have been sent!',
        birthdayDisabledText: 'This user has set a limit on sending greetings',
        event: {
            title: {
                label: 'Event name',
                placeholder: 'Event name',
            },
            startDate: {
                label: 'Start date of the event',
            },
            endDate: {
                label: 'End date of the event',
            },
            periodicity: {
                label: 'Periodicity',
            },
            type: {
                label: 'Type of event',
            },
            link: {
                label: 'Event link',
            },
            users: {
                label: 'Participants',
            },
            invited: {
                label: 'Invited',
            },
            description: {
                label: 'Description',
                placeholder: 'Description of event',
            },
        },
        days: {
            monday: 'mo',
            tuesday: 'tu',
            wednesday: 'we',
            thursday: 'th',
            friday: 'fr',
            saturday: 'st',
            sunday: 'sn',
        },
        finish: 'Finish',
        repeatSettings: 'Repetition settings',
        organizer: 'Organizer',
    },
    ie: {
        header: 'Sorry, your browser is no longer supported',
        text: 'To use the IDS Borjomi Intranet Portal, please install another browser.',
    },
    knowledgeBase: {
        folderBase: 'folder',
        fileBase: 'file',
        createFolder: 'Creating folder',
        editFolder: 'Editing folder',
        toCreateFolder: 'Create folder',
        toEditFolder: 'Edit folder',
        title: 'Title',
        author: 'Author',
        createdAt: 'Created date',
        size: 'Size',
        folder: {
            name: {
                label: 'Folder name',
                placeholder: 'Enter folder name',
            },
            description: {
                label: 'Description',
                placeholder: 'Enter a folder description',
            },
            author: 'Author',
            createdAt: 'Created At',
            size: 'Size',
        },
        emptyRootTitle: 'Page "Documents" is empty',
        emptyRootDescription: 'Create a folder',
        emptyTitle: 'The folder is empty',
        emptyDescription: 'Move files into this window, to download them.',
        uploadIcon: 'Upload an icon for a folder',
    },
    bookmarks: {
        myLinks: 'My links',
        addLink: 'Adding a link',
        editLink: 'Editing a link',
        toAddLink: 'Add link',
        link: {
            title: {
                label: 'Link name',
                placeholder: 'Enter link name',
            },
            url: {
                label: 'URL',
                placeholder: 'http://',
            },
        },
    },
    policy: {
        h1: 'Rules for using the portal',
        p1: 'Dear Colleagues, welcome to ',
        p1_1: 'Intranet portal!',
        p2: 'This is the place where we, all IDS Borjomi employees, can learn more about the Group and its strategy, share news and access up-to-date information and services that help us in our day-to-day work.',
        p3: 'By entering the portal, you agree to adhere to the following rules:',
        li1: 'observe the ethics of communication and respect the opinions of others;',
        li2: 'refrain from discussing acute social topics and personal issues, including money and health;',
        li3: 'adhere to the principles of transparency and absence of anonymity when posting your information;',
        li4: 'remember that the portal is intended exclusively for internal use by the Company employees, meaning that the content is subject to the confidentiality rules;',
        li5: 'observe grammar rules when posting;',
        li6: 'know the portal administration may refuse publishing or delete content if it violates any announced rule.',
        p4: 'The following is unacceptable on',
        p4_1: '',
        li7: 'profanity, rudeness, personal attacks, as well as insulting national, religious or moral feelings;',
        li8: 'posting knowingly false statements;',
        li9: 'posting links to pirated commercial software, hacking patches and key generators, as well as copyrighted materials;',
        li10: 'acting as a moderator by reproving other users without the appropriate authority;',
        li11: 'discussing and condemning the actions of users, including moderators and administrators.',
        p5: 'The administrators can issue warnings and block user accounts for violating the rules, although we would not like to do that.',
        p6: 'For all technical questions, contact IT Service Desk',
        p7: 'for content-related issues, contact Ask IDS Borjomi',
        p8: 'Let',
        p8_1: 'bring only benefits for your work and communication!',
        p9: 'Internal Communication Team',
    },
}

export default locale
