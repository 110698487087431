import ApiService from "../services/api.service"
import { destroyTokenData, getTokenData, saveTokenData } from "../services/token.service"
import i18n from '../plugins/vue-i18n';
import i18nService from "../services/i18n.service";
import { BUSINESS_ROLE, OTHERS_BUSINESS_ROLE } from '../constants/user-roles'

const state = {
  user: {},
  isAuthenticated: !!getTokenData(),
  preparedLoginData: null,
}

const getters = {
  getPreparedLoginData: state => state.preparedLoginData,
  isAuthenticated: state => state.isAuthenticated,
  getRole: state => state.user.role,
  getBusinessRoles: state => state.user.businessRoles,
  isSuperAdmin: state => ['ROLE_SUPER_ADMIN'].includes(state.user.role),
  isAdmin: state => ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(state.user.role),
  getPermissions: state => state.user.permissions,
  getUser: state => state.user,
  isBudgetAdmin: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === BUSINESS_ROLE.BUDGET_ADMIN),
  isAdminManager: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === BUSINESS_ROLE.ADMINISTRATIVE_MANAGER),
  isAccountant: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === BUSINESS_ROLE.ACCOUNTANT),
  isHrDirector: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === BUSINESS_ROLE.HR_DIRECTOR),
  isBudgetOwner: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === BUSINESS_ROLE.BUDGET_OWNER),
  isSubBudgetOwner: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === OTHERS_BUSINESS_ROLE.SUB_BUDGET_OWNER),
  isAlternate: state => state.user.businessRoles && state.user.businessRoles.some(i => i.alias === OTHERS_BUSINESS_ROLE.ALTERNATE),
  isSuperUser: state => state.user.extensionAttribute,
  isSimpleEmployee: stat => stat.user.businessRoles && !stat.user.businessRoles.length
}

const actions = {
  async onTfaState (context, credentials) {
    const oAuthRequired = {
      client_id: process.env.VUE_APP_OAUTH_V2_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_V2_CLIENT_SECRET,
      grant_type: 'password',
      tfa: 'email',
    }
    const postData = { ...oAuthRequired, ...credentials }
    return ApiService.post('oauth/token', postData)
  },
  onLogin (context, credentials) {
    return new Promise((resolve, reject) => {
      const oAuthRequired = {
        client_id: process.env.VUE_APP_OAUTH_V2_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_V2_CLIENT_SECRET,
        grant_type: 'http://tfa.email',
      }
      const postData = { ...oAuthRequired, ...credentials }
      ApiService.post('oauth/token', postData)
        .then(({ data }) => {
          context.commit('setAuth', data.payload)
          resolve(data.payload);
        })
        .catch((data) => {
          reject(data)
        })
    })
  },
  onLogout (context) {
    context.commit('purgeAuth')
    localStorage.removeItem('greeting')
    // this.$route.push('login')
    location.href = '/login'
  },
  onRefreshToken () {
    const tokenData = getTokenData()
    const refresh_token = tokenData ? JSON.parse(tokenData).refresh_token : ''
    const postData = {
      client_id: process.env.VUE_APP_OAUTH_V2_CLIENT_ID,
      client_secret: process.env.VUE_APP_OAUTH_V2_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token,
    }
    return ApiService.post('oauth/token', postData)
  },
  onVerifyAuth (context) {
    if (getTokenData()) {
      if (!context.state.user.uuid) return context.dispatch('onGetUser')
    } else {
      context.commit('purgeAuth')
    }
  },
  onGetUser (context) {
    return new Promise(resolve => {
      ApiService.get('profile')
        .then(({ data }) => {
          context.commit('setUser', data.payload)
          resolve(data.payload)
          i18n.locale = data.payload.locale || 'ru'
          i18nService.setActiveLanguage(data.payload.locale || 'ru')
        })
    })
  },
  setTimezone () {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return ApiService.post('time-zone/set', { timeZone })
  },
}

const mutations = {
  setPrepareLoginData (state, payload) {
    state.preparedLoginData = payload
  },
  setUser (state, data) {
    state.user = data
  },
  setAuth (state, data) {
    state.isAuthenticated = true
    state.errors = {}
    saveTokenData(JSON.stringify(data))
  },
  purgeAuth (state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    destroyTokenData()
  },
  updateReceiveMessage: (state, { type, value }) => state.user[type] = value,

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
