import Vue from 'vue';
const moment = require('moment')

const locales = {
  en: 'en-gb',
  ka: 'ka',
  ru: 'ru',
  ua: 'uk',
}
const locale = localStorage.getItem('language') || 'ru'
require('moment/locale/' + locales[locale])
Vue.use(require('vue-moment'), {
  moment,
})
