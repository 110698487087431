import i18n from '../plugins/vue-i18n'

export const ROLES = [
  {
    uuid: 'ROLE_ADMIN',
    name: i18n.t('roles.ROLE_ADMIN'),
  },
  {
    uuid: 'ROLE_LDAP_USER',
    name: i18n.t('roles.ROLE_LDAP_USER'),
  },
]

export const BUSINESS_ROLE = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    BUDGET_ADMIN: 'budget_admin',
    BUDGET_OWNER: 'budget_owner',
    HR_DIRECTOR: 'hr_director',
    ADMINISTRATIVE_MANAGER: 'administrative_manager',
    ACCOUNTANT: 'accountant',
    HR: 'hr',
}

export const OTHERS_BUSINESS_ROLE = {
    ALTERNATE: 'alternate',
    SUB_BUDGET_OWNER: 'sub_budget_owner',
}
