import Vue from 'vue'

Vue.use(() => {
  Vue.directive('permissions', {
    inserted: (el, binding) => {
      checkPermission(el, binding)
    },
    update (el, binding) {
      checkPermission(el, binding)
    },
  })
  function checkPermission (el, binding) {
    const { value, arg } = binding
    if (!value) {
      el.parentNode && el.parentNode.removeChild(el)
    } else {
      //  TODO need to add logic for appear hidden elements
    }
  }

})
