import { getTokenData } from './token.service';
import store from '../store'
import axios from 'axios';
import { prepareParams, paramsSerializerQs } from '../helpers/api'
import i18nService from "./i18n.service";
const ApiService = {
  constructor () {
    this.instance = null
  },
  init () {
    this.instance = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_ORIGIN}/api/v1`,
    });
    this.instance.interceptors.request.use(
      (config) => {
        const locale = config.loc || i18nService.getActiveLanguage()
        config.headers['Accept-Language'] = locale
        const tokenData = getTokenData()
        if (tokenData) {
          const { access_token } = JSON.parse(tokenData)
          if (access_token) config.headers.Authorization = `Bearer ${access_token}`
          return config
        } else return config
      },
      error => Promise.reject(error),
    )
    let isRefreshing = false
    let subscribers = []
    const subscribeTokenRefresh = cb => subscribers.push(cb)
    const onRrefreshed = token => subscribers.map(cb => cb(token))
    this.instance.interceptors.response.use(undefined, err => {
      const config = err.config
      const status = err.response ? err.response.status : null
      const originalRequest = config
      if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true
          store.dispatch('auth/onRefreshToken')
            .then(({ data }) => {
              isRefreshing = false
              store.commit('auth/setAuth', data.payload)
              onRrefreshed(data.payload.access_token)
              subscribers = []
            })
            .catch(() => {
              store.dispatch('auth/onLogout')
            })
        }
        const requestSubscribers = new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(axios(originalRequest))
          })
        })
        return requestSubscribers
      }
      return Promise.reject(err)
    })
  },
  request (url, params, cfg) {
    const config = { params: prepareParams(params), paramsSerializer: paramsSerializerQs, ...cfg }
    return this.instance.get(url, config);
  },
  get (url, params, headers) {
    console.log(headers)
    const { loc, ...rest } = params || {}
    const config = { params: prepareParams(rest), paramsSerializer: paramsSerializerQs }
    config.loc = loc
    if (headers) config.headers = headers
    return this.instance.get(url, config)
  },
  postFile (url, data, params = {}) {
    const config = { ...params, headers: { 'Content-Type': 'multipart/form-data' } }
    return this.instance.post(url, data, config)
  },
  post (url, data, params) {
    return this.instance.post(`${url}`, data, params);
  },
  update (url, slug, params) {
    return this.instance.put(`${url}/${slug}`, params);
  },
  put (url, params) {
    return this.instance.put(`${url}`, params);
  },
  patch (url, data, params) {
    return this.instance.patch(`${url}`, data, params);
  },
  delete (url, data) {
    return this.instance.delete(url, { ...data })
  },
}
export default ApiService
