export default [
  {
    path: '/admin/news',
    name: 'indexNewsAdmin',
    meta: { title: 'indexNews' },
    component: () => import('@/pages/admin/news'),
  },
  {
    path: "/admin/news/:id",
    name: "viewNewsAdmin",
    meta: { title: 'viewNews' },
    component: () => import('@/pages/admin/news/id'),
  },
  {
    path: "/admin/news/:id/edit",
    name: "editNewsAdmin",
    meta: { title: 'editNews' },
    component: () => import('@/pages/admin/news/id/edit'),
  },
]
