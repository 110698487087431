import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
// import {
//   LayoutPlugin,
//   ButtonPlugin,
//   FormPlugin,
//   FormGroupPlugin,
//   FormInputPlugin,
//   SpinnerPlugin
// } from 'bootstrap-vue'
// Vue.use(LayoutPlugin)
// Vue.use(ButtonPlugin)
// Vue.use(FormPlugin)
// Vue.use(FormGroupPlugin)
// Vue.use(FormInputPlugin)
// Vue.use(SpinnerPlugin)
