<template>
  <router-view />
</template>

<script>
  import WebFontLoader from 'webfontloader'

  export default {
    name: 'App',
    data () {
      return {
        greeting: {},
      }
    },
    created () {
      WebFontLoader.load({
        events: false,
        // google: {
          // families: ['Roboto:wght@400;500;600;700&display=swap'],
        // },
        // custom: {
        //   families: ['Arial'],
        //   urls: ['fonts/fonts.css']
        // },
        timeout: 5000,
      });
    },
  }
</script>

<style lang="scss">
  @import "~bootstrap-vue/dist/bootstrap-vue.css";
  @import "assets/sass/style.vue";
</style>
