import store from '@/store'

export default [
    {
        path: '/admin/dictionaries',
        name: 'indexDictionariesAdmin',
        meta: { title: 'indexDictionariesAdmin' },
        component: () => import('@/pages/admin/dictionaries'),
    },
]
