import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { locale as en } from '@/assets/locales/en'
import { locale as ru } from '@/assets/locales/ru'
import { locale as ka } from '@/assets/locales/ka'
import { locale as ua } from '@/assets/locales/ua'

Vue.use(VueI18n)

// get current selected language
const lang = localStorage.getItem('language') || 'ru'

const pluralizationRule = function (choice, choicesLength) {
  if (choice === 0) {
    return 0
  }
  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2
  }
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }
  return (choicesLength < 4) ? 2 : 3
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages: { ru, en, ka, ua }, // set locale messages
  pluralizationRules: {
    'ru': pluralizationRule,
    'ua': pluralizationRule,
  },
})

export default i18n
