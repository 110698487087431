export default [
  {
    path: '/admin/pages',
    name: 'indexPagesAdmin',
    meta: { title: 'indexPagesAdmin' },
    component: () => import('@/pages/admin/admin-pages'),
  },
  {
    path: "/admin/pages/create",
    name: "createPageAdmin",
    meta: { title: 'createPageAdmin' },
    component: () => import('@/pages/admin/admin-pages/create'),
  },
  {
    path: "/admin/pages/:id/edit",
    name: "editPageAdmin",
    meta: { title: 'editPageAdmin' },
    component: () => import('@/pages/admin/admin-pages/edit'),
  },
]
