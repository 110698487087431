export const locale = {
  base: {
      general: 'Загальне',
      aprove: 'Затвердити',
      subscribe: 'Підписатися',
      unsubscribe: 'Відписатися',
      toSubscriptions: 'До підписок',
      subscribtions: 'Підписки',
      colleagues: 'Колеги',
      search: 'Пошук',
      searchMain: 'Пошук новин, співробітників, документів, подій',
      emptyList: 'Порожній список',
      publish: 'Опублікувати',
      unlock: 'Розблокувати',
      published: 'Опублікувати',
      rejected: 'Відхилити',
      send: 'Відправити',
      saveAsDraft: 'Зберегти у чернетки',
      save: 'Зберегти',
      select: 'Вибрати',
      noData: 'Немає даних',
      toMain: 'На головну',
      myArticles: 'Мої публікації',
      myDrafts: 'Мої чернетки',
      exit: 'Вийти',
      edit: 'Редагувати',
      delete: 'Видалити',
      close: 'Закрити',
      leave: 'Залишити',
      more: 'Ще',
      add: 'Додати',
      showMore: 'Показати ще',
      hideMore: 'Приховати',
      answer: '0 відповідей | {n} відповідь | {n} відповіді | {n} відповідей',
      category: '0 категорій | {n} категорія | {n} категорії | {n} категорій',
      clustery: '0 регіонів | {n} регіонів | {n} регіонів | {n} регіонів',
      categories: 'Категорії',
      clusters: 'Регіони',
      clusterOne: 'Регіон',
      categoryOne: 'Категорія',
      answered: 'Відповів',
      upload: 'Завантажити',
      uploadFile: 'Завантажити файл',
      uploadImg: 'Завантаження зображення',
      supportedFormats: 'Допустимі формати: {value}.',
      supportedSize: 'Розширення: {value}.',
      fileUploaded: 'Файл завантажено',
      yes: 'Так',
      no: 'Ні',
      maybe: 'Можливо',
      join: 'Приєднатися',
      tune: 'Налаштувати',
      result: 'Результат',
      congratulate: 'Привітати',
      congratulation: 'Привітання',
      actions: 'Дії',
      commentOrigin: 'Коментар',
      writeComment: 'Написати коментар...',
      department: 'департамент',
      create: 'Створити',
      articleModerationEnabled: 'Модерація новин',
      cancel: 'Скасувати',
      comment: '0 Коментарів | {n} Коментар | {n} Коментарі | {n} Коментарів',
      seeComments: 'Показати інші коментарі',
      reply: 'Відповісти',
      myProfile: 'Мій профіль',
      profileSetting: 'Налаштування профілю',
      companyStructure: 'Структура компанії',
      businessTrips: 'Відрядження',
      portal: 'Портал',
      adminPanel: 'Адмін панель',
      never: 'Ніколи',
      date: 'Дата',
      time: 'Час',
      after: 'Після',
      liked: 'Оцінили',
      selectDate: 'Виберіть дату',
      times: 'раз',
      subTitleDocuments: 'Підзаголовок для документів',
      subTitle: 'підзаголовок',
      sizes: ['Байт', 'КБ', 'МБ', 'ГБ'],
      cluster: 'Регіон',
      timezoneInfo: 'Час відображається у часовому поясі Europe GMT +3:00',
      saveData: 'Дані успішно збережено',
      icons: 'Іконки',
      share: 'Поділитися',
      enterName: 'Введіть назву',
      sum: 'Сума',
      currency: 'Валюта',
      rate: 'Курс',
      noItemsByParams: 'За заданими параметрами нічого не знайдено',
      deleteNotAvailable: 'Видалити неможливо – елемент уже використовується.',
      byDefault: 'За замовчуванням',
      others: 'Інше',
      ok: 'Добре',
      filter: 'Фільтр',
      filters: 'Фільтри',
      apply: 'Застосувати',
      clear: 'Очистити',
      enterLocation: 'Введіть місце розташування'
  },
  subscribe: {
      successNewsTitle: 'Ви підписалися на новини {name}',
      successNewsDescription: 'Повідомлення про нові публікації користувача {name} будуть надходити Вам на пошту.',
  },
  lang: {
      ru: 'Російська',
      en: 'Англійська',
      ka: 'Грузинська',
      ua: 'Українська',
  },
  actions: {
      create: 'Створення',
      remove: 'Видалення',
      update: 'Редагування',
  },
  settings: {
      articleModerationEnabled: {
          true: 'Активний',
          false: 'Неактивний',
      },
  },
  statuses: {
      allStatuses: 'Усі публікації',
      published: 'Опубліковані',
      rejected: 'Відхилені',
      draft: 'Чернетки',
      moderation: 'На модерації',
  },
  roles: {
      ROLE_SUPER_ADMIN: 'Супер Адміністратор',
      ROLE_ADMIN: 'Адміністратор',
      ROLE_LDAP_USER: 'Співробітник',
  },
  businessRoles: {
      ROLE_ADMIN: 'Адміністратор',
      super_admin: 'Супер Адміністратор',
      budget_admin: 'Адміністратор бюджетів',
      budget_owner: 'Власник бюджетів',
      alternate: 'Заступник',
      sub_budget_owner: 'Власник підбюджету',
      hr_director: 'Директор з управління персоналом',
      administrative_manager: 'Адміністративний менеджер',
      accountant: 'Бухгалтер',
      hr: 'Відділ кадрів',
      employee: 'Співробітник',
      all: 'Усі ролі',
  },
  socialNetworks: {
      facebook: 'Facebook',
      instagram: 'Instagram',
      linkedin: 'LinkedIn',
      twitter: 'Twitter',
      skype: 'Skype',
      telegram: 'Telegram',
      vk: 'ВКонтакті',
      ok: 'Однокласники',
      strava: 'Strava',
  },
  leavePage: {
      question: 'Бажаєте залишити сторінку?',
      info: 'Всі ваші зміни будуть втрачені.',
  },
  page: {
      login: 'Авторизація',
      indexNews: 'Новини',
      viewNews: 'Новина',
      createNews: 'Створення новини',
      createBusinessTrip: 'Створення відрядження',
      editNews: 'Редагування новини',
      indexNewsAdmin: 'Новини',
      indexTopicAdmin: 'Категорії новин',
      viewTopicAdmin: 'Категорія',
      createTopicAdmin: 'Створення категорії',
      editTopicAdmin: 'Редагування категорії',
      viewProfile: 'Профіль',
      indexLogsAdmin: 'Логування',
      indexBannerAdmin: 'Банер',
      createBannerAdmin: 'Створення банера',
      editBannerAdmin: 'Редагування банера',
      indexQuizAdmin: 'Опитування',
      indexUsersAdmin: 'Користувачі',
      indexSettingsAdmin: 'Налаштування',
      knowledgeBase: 'Документи',
      indexPagesAdmin: 'Сторінки',
      createPageAdmin: 'Створення сторінки',
      editPageAdmin: 'Редагування сторінки',
      indexRegionAdmin: 'Регіони',
      indexDictionariesAdmin: 'Словники',
      indexSubmittingBudgets: 'Внесення бюджетів',
      greetingAdmin: 'Вітання',
      events: 'Події',
      createEvent: 'Створення події',
      editEvent: 'Редагування події',
      editBusinessTrip: 'Редагування заявки',
      indexSearch: 'Пошук',
      'about-company': 'Про компанію',
      'about-products': 'Про продукцію',
      'company-structure': 'Структура компанії',
      collaboration: 'Сумісна праця',
      welcome: 'Ласкаво просимо!',
      404: '404',
      policy: 'Умови використання порталу',
      viewBusinessTrips: 'Відрядження',
  },
  subscribtions: {
      noUsers: 'На даний момент ви не підписані на своїх колег.',
  },
  error: {
      404: {
          title: 'Такої сторінки не знайдено :(',
          desc: 'Можливо вона була видалена або змінила адресу',
          btn: 'перейти на головну',
      },
  },
  modal: {
      areYouSureToDelete: 'Ви впевнені, що хочете видалити?',
      deletingTextModal: 'Видалення регіону',
      areLongTime: 'Процес може зайняти тривалий час...',
  },
  indexNews: {
      new: 'Новина',
      likedNew: 'Не сподобалося | Сподобалося {n} людині | Сподобалося {n} людям | Сподобалося {n} людям',
  },
  greeting: {
      show: 'Не показувати привітання ',
      create: 'Створення привітання',
      edit: 'Редагування привітання',
      preview: 'Передпроглядання привітання',
      form: {
          image: {
              label: 'Завантажте обкладинку привітання',
          },
          text: {
              label: 'Текст привітання ',
              placeholder: 'Введіть привітання ',
          },
      },
  },
  createNews: {
      titleNew: 'Створення новини',
      titleEdit: 'Редагування новини',
      preview: 'Перегляд новини',
      form: {
          title: 'Назва',
          subTitle: 'Підзаголовок',
          topic: 'Категорія новини',
          image: 'Зображення',
          body: 'Зміст',
          commentable: 'Дозволити коментувати новини',
          refuseReason: 'Причина',
          slug: 'Слаг',
      },
      placeholder: {
          title: 'Введіть назву',
          subTitle: 'Введіть підзаголовок',
          titleEn: 'Назва локальною мовою',
          text: 'Введіть основний текст',
          topic: 'Оберіть категорію',
          refuseReason: 'Введіть причину',
          slug: 'Введіть слаг',
      },
      uploadImageTitle: 'Завантажте обкладинку новини',
      uploadImageDesc: 'Допустимі формати: JPEG, PNG. Максимальний розмір: 8 МБ.<br/> Мінімальна роздільна здатність: {size}.',
      refuseReason: 'Причина відхилення',
  },
  viewNews: {
      isModerate: 'Новина проходить модерацію',
      shareNews: 'Поділитися новиною із колегами',
      newsSent: 'Новину відправлено',
      statuses: {
          rejected: 'Новина не пройшла модерацію',
          rejectedDesc: 'Ваша новина не пройшла модерації. Зверніться до адміністратора.',
          rejectedSuccess: 'Новина успішно відхилена',
          draft: 'Новина у чернетках',
          draftDesc: 'Вашу новину не опубліковано.',
          moderation: 'Новина на модерації',
          moderationDesc: 'Вашу новину додано в чергу на модерацію. Вона з\'явиться на порталі у випадку, якщо один з модераторів її схвалить.',
          published: 'Новина опублікована',
          publishedDesc: 'Ваша новина пройшла модерацію та опублікована на порталі.',
          waitModeration: 'Після модерації новину буде опубліковано на порталі.',
          savedToDrafts: 'Новина збережена в чернетки',
          offModeration: 'Вимкнути режим модерації?',
          offModerationDesc: 'Всі новини, що очікують на модерацію, будуть автоматично опубліковані.',
      },
  },
  indexNewsAdmin: {
      name: 'Назва',
      author: 'Автор',
      status: 'Статус',
  },
  indexTopicAdmin: {
      new: 'Категорію',
      name: 'Назва',
      shared: 'Доступна для публікації',
  },
  indexRegionAdmin: {
      new: 'Регіон',
      name: 'Назва',
      regionExists: 'Така назва регіону вже існує',
  },
  indexDictionariesAdmin: {
      tripPurposes: 'Мета поїздки',
      currencies: 'Валюти',
      transportTypes: 'Типи транспорту',
      ticketClasses: 'Клас квитка',
      services: 'Сервіси',
      currencyCode: 'Код валюти',
      availableForPublish: 'Доступна для публікації',
      availableForSelection: 'Доступно для вибору',
  },
  indexSubmittingBudgets: {
      description: 'Внесіть бюджети/підбюджети, курс валют та добові',
      budgets: 'Бюджети',
      reports: 'Звіти',
      currencyRates: 'Курс валют',
      dailyAllowance: 'Добові',
  },
  indexUsersAdmin: {
      name: 'Ім\'я',
      role: 'Роль',
      roles: 'Ролі',
      department: 'Департамент',
      alternates: 'Заступники',
      alternate: 'Заступник',
      subBudgets: 'Власники підбюджету',
      subBudget: 'Власник підбюджету',
      previewText: 'Надайте активні ролі ключовим користувачам',
      roleAlreadyExist: 'Роль вже присвоєно {value}',
      budgetOwnersAlternate: 'Заступник у власника бюджету',
      budgetOwnersSubBudgetOwner: 'Власник підбюджету у власника бюджету',
  },
  indexLogsAdmin: {
      date: 'Дата час',
      entity: 'Сутність',
      name: 'Назва',
      action: 'Дія',
      author: 'Автор',
      businessTripName: 'Назва/№ заявки',
  },
  indexSettingsAdmin: {
      name: 'Ім\'я',
      status: 'Статус',
  },
  indexBannerAdmin: {
      name: 'Название',
      status: 'Статус',
      statuses: {
          active: 'Активний',
          notActive: 'Неактивний',
      },
      new: 'Банер',
  },
  indexQuizAdmin: {
      new: 'Опитування',
      dateAndTime: 'Дата, время',
      question: 'Питання',
      status: 'Статус',
  },
  createQuizAdmin: {
      creating: 'Створення опитування',
      editing: 'Редагування опитування',
      form: {
          question: 'Питання',
          answer: 'Варіант відповіді {number}',
          anonymous: 'Анонімне опитування',
      },
      placeholder: {
          question: 'Задайте питання',
          answer: 'Введіть відповідь',
      },
  },
  createBannerAdmin: {
      preview: 'Перегляд банера',
      titleNew: 'Створення банера',
      titleEdit: 'Редагування банера',
      titleCrop: 'Завантаження обкладинки',
      uploadImageTitle: 'Завантажте обкладинку банеру або банер',
      uploadImageDesc: 'Допустимі формати: JPEG, PNG. Максимальний розмір: 8 МБ. Мінімальна роздільна здатність: 800х100.',
      form: {
          textBanner: 'Текст банера',
          textColor: 'Колір тексту',
          textColorBanner: 'Колір тексту банера',
          textBtnColor: 'Колір тексту кнопки',
          btnColor: 'Колір кнопки',
          textBtn: 'Текст кнопки',
          link: 'Посилання для кнопки',
          startDate: 'Старт публікації',
          timeStart: 'Время',
          endDate: 'Закінчення публікації',
          timeEnd: 'Час',
      },
      placeholder: {
          textBanner: 'Введіть основний текст банера',
          textBtn: 'Введіть текст кнопки',
          link: 'Вставте посилання',
          startDate: 'Дата',
          timeStart: 'Час',
          endDate: 'Дата',
          timeEnd: 'Час',
      },
      danger: {
          startDate: 'Дата публікації',
          endDate: 'Дата закінчення публікації',
      },
      activeMessage: 'Активним може бути лише один банер',
  },
  createTopicAdmin: {
      creating: 'Створення категорії',
      editing: 'Редагування категорії',
      form: {
          name: 'Назва категорії',
          shared: 'Доступна для публікації',
      },
      placeholder: {
          name: 'Введіть назву категорії',
      },
  },
  createRegionAdmin: {
      creating: 'Створення регіону',
      editing: 'Редагування регіону',
      form: {
          name: 'Назва регіону',
          rawCountries: 'Назви країн',
      },
      placeholder: {
          name: 'Введіть назву регіону',
      },
  },
  editPageAdmin: {
      title: 'Редагування сторінки',
      infoIcon: 'Завантажте значок для сторінки',
      subtitle: 'Підзаголовок локальною мовою',
      subtitleEn: 'Підзаголовок англійською мовою',
      localTitle: 'Назва локальною мовою',
      englishTitle: 'Назва англійською мовою',
  },
  createDictionariesAdmin: {
      title: {
          trip_purpose: 'Створення мети поїздки',
          currencies: 'Створення валюти',
          transport: 'Створення типу транспорту',
          ticket: 'Створення класу квитка',
          service: 'Створення сервісу',
      },
      label: {
          trip_purpose: 'Мета поїздки',
          currencies: 'Валюта',
          transport: 'Тип транспорту',
          ticket: 'Клас квитка',
          service: 'Сервіс',
      },
      placeholder: 'Введіть назву',
      child: {
          transport: 'Клас квитка',
      },
  },
  editDictionariesAdmin: {
      title: {
          trip_purpose: 'Редагування мети поїздки',
          currencies: 'Редагування валюти',
          transport: 'Редагування типу транспорту',
          ticket: 'Редагування класу квитка',
          service: 'Редагування сервісу',
      },
  },
  createBudgetAdmin: {
      title: 'Створення бюджету',
      subBudgetTitle: 'Створення підбюджету',
      budget: 'Бюджет',
      subBudget: 'Підбюджет',
      budgetOwner: 'Власник бюджету',
      subBudgetOwner: 'Власник підбюджету',
      budgetSubbudgetName: 'Назва бюджету/підбюджету',
      budgetName: 'Назва бюджету',
      subBudgetName: 'Назва підбюджету',
      defaultBudget: 'Бюджет за замовчуванням',
      defaultSubBudget: 'Підбюджет за замовчуванням',
      placeholder: {
          user: 'Оберіть власника бюджету',
          subBudgetOwner: 'Оберіть власника підбюджету',
          name: 'Введіть назву бюджету',
          subName: 'Введіть назву підбюджету',
          year: 'Виберіть рік',
      },
      createBudget: 'Створити бюджет',
      createSubBudget: 'Створити підбюджет',
      empty: 'Бюджет ще не створено',
      emptyByParams: 'За заданими параметрами нічого не знайдено',
  },
  editBudgetAdmin: {
      title: 'Редагування бюджету',
      subBudgetTitle: 'Редагування підбюджету',
  },
  createCurrencyRate: {
      currencyRate: 'Курс валют',
      addRate: 'Додати курс',
      placeholders: {
          date: 'Виберіть дату',
      },
      empty: 'Курс валют ще не створено',
  },
  editDailyAllowance: {
      dailyAllowance: 'Добові',
      dailyAllowanceSize: 'Розмір добових',
      travelDirection: 'Напрямок поїздки',
      travelDirectionPlaceholder: 'Введіть напрямок подорожі',
      create: 'Створити добові',
      creating: 'Створення добових',
      editing: 'Редагування добових',
  },
  budgetReport: {
      total: 'Усього',
      used: 'Використані',
      remaining: 'Залишок',
      owners: 'Власник(и) бюджету/підбюджету',
      budget: 'Бюджет',
      sub_budget: 'Підбюджет',
      noBudgets: 'Бюджет ще не створено',
      allDepartments: 'Усі департаменти',
      corporate: 'Корпоративний',
      finance: 'Фінанси',
      spent: 'Витрачено',
      amountPending: 'Сума ($) на розгляді',
  },
  businessTrips: {
      faq: 'Посібник для функціоналу {link}',
      faqText: '«Відрядження»',
      transfer: 'Трансфер',
      route: 'Маршрут',
      created: 'Створено',
      title: 'Відрядження',
      allTrips: 'Усі заявки',
      daysCount: 'Кількість днів',
      myTrips: 'Мої заявки',
      trip: 'Заявка',
      trips: 'Заявки',
      approved: 'Затверджені',
      inProcessing: 'В обробці',
      completed: 'Завершені',
      budgets: 'Бюджети',
      reportsByEmployee: 'Звіти по співробітникам',
      number: 'Номер',
      status: 'Статус',
      employeeOrNot: 'Співробітник/ Консультант',
      tripPurpose: 'Мета поїздки',
      destination: 'Пункт призначення',
      startDateToEndDate: 'Дата старту/ закінчення',
      plan: 'План',
      fact: 'Факт',
      planSum: 'Сума ({currencySymbol}) план',
      factSum: 'Сума ({currencySymbol}) факт',
      filters: {
          startDate: 'Дата старту',
          endDate: 'Дата закінчення',
          createdDate: 'Дата створення',
      },
      deleteReason: 'Причина видалення',
      roleStatuses: {
          approved: 'Заявку затверджено',
          pending: 'Заявка на затвердженні',
          rejected: 'Заявка відхилена',
      },
      factSpent: 'Фактичні витрати',
      pinnedEmployees: 'Закріплені працівники',
      viewFrom: 'Ви переглядаєте сторінку від ',
      fromAllBudgetOwners: 'Всіх власників бюджету',
      inputAccountantExpensesReason: 'Введіть причину перевищення фактичних витрат',
  },
  createBusinessTrip: {
      title: 'Створення відрядження',
      employee: 'Співробітник',
      notEmployee: 'Консультант',
      fio: 'ПІБ',
      startBusinessTrip: 'Початок відрядження',
      endBusinessTrip: 'Кінець відрядження',
      dayCounts: 'Кількість днів: {count}',
      additional: 'Додатково',
      transferAirport: 'Трансфер до/з аеропорту',
      transferOffice: 'Трансфер до/з офісу або іншого пункту',
      datePlaceTime: 'Дата, місце та час',
      transportType: 'Тип транспорту',
      cityAndCountry: 'Місто, країна',
      country: 'Країна',
      city: 'Місто',
      placeholder: {
          fio: 'Введіть ПІБ',
          user: 'Виберіть співробітника',
          transportType: 'Виберіть тип транспорту',
          ticketType: 'Виберіть клас квитка',
          additional: 'Виберіть додаткові послуги',
          transferAirport: 'Вкажіть дату, місце та час',
          otherInfo: 'Побажання щодо поїздки',
      },
      errors: {
          cantCreateTrip: 'Неможливо створити відрядження',
          notFoundRoleAndBudgetCount: 'Для Вашого юніту бізнес не був призначений {roles} і не був виділений бюджет. Будь ласка, зверніться до Вашого керівника.',
          not_found_role: 'Для Вашого юніту бізнес не був призначений {roles}. Будь ласка, зверніться до Вашого керівника.',
          budget_count: 'Для Вашого бізнес-юніту не було виділено бюджет. Будь ласка, зверніться до Вашого керівника.',
      },
      empty: 'У Вас ще не було відряджень',
      emptyDesc: 'Створіть заявку на відрядження',
      arrange: 'Оформити',
      successGoToApprove: 'Заявка {number} надіслана на обробку.',
      successGoToReApprove: 'Заявка {number} надіслана на повторне затвердження.',
      successClosed: 'Заявка {number} закрита.'
  },
  editBusinessTrip: {
      title: 'Редагування відрядження',
      request: 'Заявка {number}',
      budgetOwnerForApprove: 'Власник бюджету/підбюджету',
      budget: 'Назва бюджету/підбюджету',
      hotelPrice: 'Вартість проживання',
      transportPrice: 'Вартість транспорту',
      transferPrice: 'Вартість трансферу',
      dailyPayment: 'Добові',
      otherExpenses: 'Інше',
      otherExpensesDestination: 'Призначення суми "Інше"',
      total: 'Усього',
      placeholder: {
          budgetOwnerForApprove: 'Виберіть зі списку',
          budget: 'Виберіть зі списку',
      },
      reApproval: 'Повторне затвердження',
      reApprovalDesc: 'Надіслати заявку на повторне затвердження?',
      wishes: 'Побажання',
      accommodation: 'Проживання',
      transport: 'Транспорт',
      transfer: 'Трансфер',
      uploadDocumentsTickets: 'Завантажте документи, квитки'
  },
  businessTripStatuses: {
      created: 'В обробці',
      wait_approval: 'Чекає на затвердження',
      approved: 'Затверджено',
      partially_approved: 'Частково затверджено',
      completed: 'Оформлено',
      closed: 'Закрито',
      decline: 'Відхилено',
  },
  profile: {
      information: 'Інформація',
      articlesMy: 'Мої публікації',
      articles: 'Публікації',
      birthday: 'День народження',
      birthdaysModal: 'Дні народження',
      country: 'Країна',
      city: 'Місто',
      businessUnit: 'Бізнес-юніт',
      company: 'Компанія',
      socialProfiles: 'Соціальні профілі',
      contactInfo: 'Контактна інформація',
      phone: 'Телефон',
      mail: 'Email',
      workAddress: 'Адреса роботи',
      career: 'Кар\'єра',
      joinedToTeam: 'Приєднався(-лась) до команди IDS BORJOMI',
      leader: 'Керівник',
      personalInfo: 'Особиста інформація',
      language: 'Володіння мовами',
      certificationsAndSkills: 'Сертифікації та додаткові навички',
      workExperience: 'Досвід роботи до IDS BORJOMI',
      hobbies: 'Захоплення',
      functional: 'Функціонал',
      removedText: 'На даний момент цей співробітник не працює в компанії',
  },
  profileSettings: {
      title: 'Налаштування профілю',
      mainInformation: 'Основна інформація',
      socialProfiles: 'Соціальні профілі',
      alerts: 'Оповіщення',
      upload: 'Завантажити фото',
      receiveAlerts: 'Отримання повідомлень на поштову скриньку',
      form: {
          avatar: 'Фото',
          workAddress: 'Адреса роботи',
          languages: 'Володіння мовами',
          additionalSkills: 'Сертифікації та додаткові навички',
          prevExperience: 'Досвід роботи до IDS BORJOMI',
          hobbies: 'Захоплення',
          social: 'Соц. мережі',
          link: 'Посилання',
          functional: 'Функціонал',
          locale: 'Мова порталу',
          receivePublicationMessage: 'Відповіді на мої публікації',
          receiveBirthdayMessage: 'Вітання з днем народження',
      },
      placeholder: {
          workAddress: 'Введіть адресу',
          languages: 'Мови',
          additionalSkills: 'Напишіть про свої сертифікації та навички',
          prevExperience: 'Досвід роботи',
          hobbies: 'Напишіть про свої захоплення',
          social: 'Виберіть соц. мережі',
          link: 'Вставте посилання',
          functional: 'Опишіть, які обов\'язки ви виконуєте',
      },
  },
  staffSearch: {
      name: 'Ім\'я',
      phone: 'Телефон',
      email: 'Пошта',
  },
  search: {
      title: 'Результати пошуку',
      desc: 'На запит <span class="font-weight-bold">{search}</span> знайдено <span class="font-weight-bold">{count}</span> результатів',
      articles: 'Новини',
      staff: 'Співробітники',
      medias: 'Документи',
      events: 'Події',
      empty: {
          title: 'Пошук не дав результатів',
          desc: 'Спробуйте використати інші ключові слова',
      },
  },
  auth: {
      auth: 'Авторизація',
      authDesc: 'Щоб увійти на портал, заповніть поля нижче.',
      authConfirm: 'Перевірте свою пошту',
      authConfirmDesc: 'Ми щойно надіслали шестизначний код на адресу <span class="font-weight-boldest">{email}</span>. Не закривайте це вікно, поки перевіряєте свою поштову скриньку, а потім введіть наведений нижче код.',
      form: {
          email: 'Email',
          password: 'Пароль',
          code: 'Код',
      },
      iAccept: 'Я приймаю',
      termsOfUseOfThePortal: 'правила користування порталом',
      signIn: 'Увійти',
      didtReceiveLetter: 'Не надійшов лист?',
      sendCodeAgain: 'Надіслати код ще раз',
      codeSent: 'Ваш код надіслано повторно.',
      error: {
          invalidCredentials: 'Логін або пароль введено неправильно',
          blockAccaount: 'Ваш обліковий запис заблоковано. Щоб відновити доступ, зверніться до адміністратора',
          accountUnlock: 'Користувача розблоковано',
          invalidCode: 'Ви ввели неправильний код. Спробуйте ще раз.',
      },
  },
  validation: {
      invalid: '{name} не є допустимим',
      required: '{name} обов\'язкове поле',
      requiredIf: '{name} обов\'язкове поле',
      greaterThan: 'Має бути більше {value}',
      lessThan: 'Максимум {value}',
      requiredDays: 'Виберіть дні для налаштування повторення',
      alphaNum: '{name} повинен бути англійською мовою і без пробілів',
      alphaNumCustom: '{name} повинен бути англійською мовою і без пробілів',
      variant: 'Обов\'язкове поле',
      url: '{name} має бути url адресою',
      imageFormat: 'Неправильний формат файлу. Допустимий формат: {format}',
      imageWeight: 'Файл дуже великий. Максимальний розмір: {weight} MB',
      imageSize: 'Неправильна роздільна здатність файлу. Мінімальний розмір: {size}',
      imageMaxCount: 'Максимальна кількість завантажуваних файлів - {count}',
      fileNameMaxCount: 'Максимальна кількість символів у назві файлу - {count}',
      fileMinWeight: 'Мінімальний розмір файлу: {size} KB',
      someFileAlreadyExists: 'Один із файлів вже існує в системі',
      isAfter: '{name} має бути після дати старту',
      maxLength: 'Максимальна кількість символів - {count}',
      maxValue: 'Максимальне значення - {val}',
      fileType: 'Формат файлу, що завантажується, не підтримується системою',
  },
  quiz: {
      quiz: 'Опитування',
      startQuiz: 'Пройти опитування',
      selected: 'Відповідь обрали',
      results: 'Результати опитування',
      variant: 'Один з варіантів',
      publicResults: 'Результати',
      voting: 'Голосування',
      activeMessage: 'Активним може бути лише одне опитування',
      thankYou: 'Дякуємо за проходження опитування',
      statuses: {
          active: 'Активний',
          notActive: 'Неактивний',
      },
      peopleVote: 'Проголосувало 0 людей | Проголосувала {n} людина | Проголосували {n} людей | Проголосувала {n} людина',
      peopleSelected: 'Вибрало 0 людей | Вибрав {n} чоловік | Вибрали {n} людей | Вибрав {n} людина',
  },
  calendar: {
      birthdayDisabledText: 'Цей користувач встановив обмеження на надсилання привітань',
      toEvents: 'До подій',
      eventText: 'Подія',
      createEvent: 'Створення події',
      editEvent: 'Редагування події',
      removeEvent: 'Підтвердити видалення',
      removeEventAlert: 'Подія видалена',
      birthdays: 'Дні народження',
      units: 'Бізнес-юніти',
      year: 'Рік',
      month: 'Місяць',
      week: 'Тиждень',
      day: 'День',
      today: 'Сьогодні',
      none: 'Не повторюється',
      typeEvent: 'Захід',
      typeInfo: 'Інформаційне',
      showAllUsers: 'Показати всіх учасників',
      participating: 'Берете участь?',
      wantToJoin: 'Хочете взяти участь?',
      successJoinMessage: 'Запит на приєднання надіслано організатору',
      allParticipants: 'Усі учасники події',
      participant: '0 учасників | {n} учасник | Вибрали {n} учасника | Вибрав {n} учасник',
      // birthdayMan: '0 именинников | {n} именинник | Выбрали {n} именинника | Выбрал {n} именинников',
      birthdayMan: '0 іменинників | {n} іменинник | {n} іменинника | {n} іменинників',
      inputCongratulation: 'Введіть вітання',
      repeatAt: 'Повторювати кожну',
      onSuchDays: 'у такі дні:',
      congratulationWasSend: 'ППривітання надіслано!',
      event: {
          title: {
              label: 'Назва події',
              placeholder: 'Назва події',
          },
          startDate: {
              label: 'Дата старту події',
          },
          endDate: {
              label: 'Дата закінчення події',
          },
          periodicity: {
              label: 'Періодичність',
          },
          type: {
              label: 'Тип події',
          },
          link: {
              label: 'Посилання на подію',
          },
          users: {
              label: 'Учасники',
          },
          invited: {
              label: 'Запрошені',
          },
          description: {
              label: 'Опис',
              placeholder: 'Опис події',
          },
      },
      days: {
          monday: 'пн',
          tuesday: 'вт',
          wednesday: 'ср',
          thursday: 'чт',
          friday: 'пт',
          saturday: 'сб',
          sunday: 'нд',
      },
      finish: 'Завершити',
      repeatSettings: 'Налаштування повторення',
      organizer: 'Організатор',
  },
  ie: {
      header: 'На жаль, ваш браузер більше не підтримується',
      text: 'Для використання Інтранет-порталу IDS Borjomi встановіть інший браузер.',
  },
  knowledgeBase: {
      folderBase: 'папку',
      fileBase: 'файл',
      createFolder: 'Створення папки',
      editFolder: 'Редагування папки',
      toCreateFolder: 'Створити папку',
      toEditFolder: 'Редагувати папку',
      title: 'Назва',
      author: 'Автор',
      createdAt: 'Дата створення',
      size: 'Розмір',
      folder: {
          name: {
              label: 'Назва папки',
              placeholder: 'Введіть назву папки',
          },
          description: {
              label: 'Опис',
              placeholder: 'Введіть опис папки',
          },
          author: 'Автор',
          createdAt: 'Дата створення',
          size: 'Розмір',
      },
      emptyRootTitle: 'Сторінка "Документи" порожня',
      emptyRootDescription: 'Створіть папку',
      emptyTitle: 'Ця папка порожня',
      emptyDescription: 'Перетягніть файли у вікно, щоб завантажити їх.',
      uploadIcon: 'Завантажте іконку для папки',
  },
  bookmarks: {
      myLinks: 'Мої посилання',
      addLink: 'Додавання посилання',
      editLink: 'Редагування посилання',
      toAddLink: 'Додати посилання',
      link: {
          title: {
              label: 'Назва посилання',
              placeholder: 'Введіть назву посилання',
          },
          url: {
              label: 'URL - адреса',
              placeholder: 'http://',
          },
      },
  },
  policy: {
      h1: 'Правила користування порталом',
      p1: 'Дорогий колега, вітаємо тебе на',
      p1_1: 'Інтранет-порталі! ',
      p2: 'Тут ми, всі співробітники IDS Borjomi, можемо дізнаватись все про нашу Групу та її стратегію, ділитися новинами, отримувати доступ до актуальної інформації та сервісів, які допомагають у роботі.',
      p3: 'Заходячи на портал, ти погоджуєшся дотримуватися наступних правил:',
      li1: 'дотримуватися етики спілкування та поважати чужу думку;',
      li2: 'залишати за межами порталу обговорення гострих соціальних тем, особистих питань, включаючи гроші та здоров\'я;',
      li3: 'дотримуватися принципу прозорості та відсутності анонімності у публікації інформації;',
      li4: 'пам\'ятати про те, що портал призначений виключно для внутрішнього використання співробітників, тобто вміст є конфіденційним; ',
      li5: 'писати грамотно;',
      li6: 'бути готовим до того, що адміністрація порталу може відмовити в публікації або видалити контент на порталі, якщо він порушує будь-яке озвучене правило.',
      p4: 'На',
      p4_1: ' неприйнятно',
      li7: 'використовувати ненормативну лексику, грубі висловлювання, переходити на особи, ображати національні, релігійні та моральні почуття;',
      li8: 'публікувати свідомо неправдиву інформацію;',
      li9: 'публікувати посилання на неліцензійне комерційне програмне забезпечення, програми для його злому та генератори ключів, а також матеріали, захищені авторськими правами; ',
      li10: 'виступати в ролі модератора: робити зауваження іншим користувачам без відповідних повноважень;',
      li11: 'обговорювати та засуджувати дії користувачів, модераторів та адміністрації.',
      p5: 'За порушення правил адміністратори можуть винести попередження та заблокувати обліковий запис, але нам би цього не хотілося.',
      p6: 'З усіх технічних питань до IT Service Desk',
      p7: 'з питань контенту на Ask IDS Borjomi',
      p8: 'Нехай',
      p8_1: 'приносить тільки користь у роботі та комунікаціях!',
      p9: 'Команда внутрішніх комунікацій',
  },
}

export default locale
