<template>
  <div class="main p-4" :class="{'main-open': isAside}">
    <slot name="top"></slot>
    <div class="main-header flex-column flex-sm-row align-items-start align-items-sm-center" :style="headerStyles">
      <div class="main-title mb-4 mb-sm-0">
        <slot name="title"></slot>
      </div>
      <div class="main-toolbar flex-shrink-0">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <div  v-if="$slots.description" class="main-description mt-6 mb-4">
      <slot name="description"/>
    </div>
    <div class="main-body">
      <slot name="body"></slot>
    </div>
    <div v-if="hasFooterSlot" class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'Main',
    props: {
      headerStyles: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('base', ['isAside']),
      hasFooterSlot () {
        return !!this.$slots['footer']
      },
    },
  }
</script>

<style lang="scss" scoped>
// .main {
//   overflow: hidden;
// }
.main-toolbar {
  @media screen and (max-width: 575px) {
    margin-left: auto;
  }
}
</style>
