export const locale = {
    base: {
        general: 'Общее',
        aprove: 'Утвердить',
        subscribe: 'Подписаться',
        unsubscribe: 'Отписаться',
        toSubscriptions: 'К подпискам',
        subscribtions: 'Подписки',
        colleagues: 'Коллеги',
        search: 'Поиск',
        searchMain: 'Поиск новостей, сотрудников, документов, событий',
        emptyList: 'Пустой список',
        publish: 'Опубликовать',
        unlock: 'Разблокировать',
        published: 'Опубликовать',
        rejected: 'Отклонить',
        send: 'Отправить',
        saveAsDraft: 'Сохранить в черновики',
        save: 'Сохранить',
        select: 'Выбрать',
        noData: 'Нет данных',
        toMain: 'На главную',
        myArticles: 'Мои публикации',
        myDrafts: 'Мои черновики',
        exit: 'Выйти',
        edit: 'Редактировать',
        delete: 'Удалить',
        close: 'Закрыть',
        leave: 'Покинуть',
        more: 'Еще',
        add: 'Добавить',
        showMore: 'Показать еще',
        hideMore: 'Скрыть',
        answer: '0 ответов | {n} ответ | {n} ответа | {n} ответов',
        category: '0 категорий | {n} категория | {n} категории | {n} категорий',
        clustery: '0 регионов | {n} регионов | {n} регионов | {n} регионов',
        categories: 'Категории',
        clusters: 'Регионы',
        clusterOne: 'Регион',
        categoryOne: 'Категория',
        answered: 'Ответил',
        upload: 'Загрузить',
        uploadFile: 'Загрузить файл',
        uploadImg: 'Загрузка изображения',
        supportedFormats: 'Допустимые форматы: {value}.',
        supportedSize: 'Разрешение: {value}.',
        fileUploaded: 'Файл загружено',
        yes: 'Да',
        no: 'Нет',
        maybe: 'Возможно',
        join: 'Присоединиться',
        tune: 'Настроить',
        result: 'Результат',
        congratulate: 'Поздравить',
        congratulation: 'Поздравление',
        actions: 'Действия',
        commentOrigin: 'Комментарий',
        writeComment: 'Написать комментарий...',
        department: 'департамент',
        create: 'Создать',
        articleModerationEnabled: 'Модерация новостей',
        cancel: 'Отменить',
        comment: '0 Комментариев | {n} Комментарий | {n} Комментария | {n} Комментариев',
        seeComments: 'Показать другие комментарии',
        reply: 'Ответить',
        myProfile: 'Мой профиль',
        profileSetting: 'Настройка профиля',
        companyStructure: 'Структура компании',
        businessTrips: 'Командировки',
        portal: 'Портал',
        adminPanel: 'Админ-панель',
        never: 'Никогда',
        date: 'Дата',
        time: 'Время',
        after: 'После',
        liked: 'Оценили',
        selectDate: 'Выберите дату',
        times: 'раз',
        subTitleDocuments: 'Подзаглавие для документов',
        subTitle: 'подзаглавие',
        sizes: ['Байт', 'КБ', 'МБ', 'ГБ'],
        cluster: 'Регион',
        timezoneInfo: 'Время отображается в часовом поясе Europe GMT +3:00',
        saveData: 'Данные успешно сохранены',
        icons: 'Иконки',
        share: 'Поделиться',
        enterName: 'Введите название',
        sum: 'Сумма',
        currency: 'Валюта',
        rate: 'Курс',
        noItemsByParams: 'По заданным параметрам ничего не найдено',
        deleteNotAvailable: 'Удалить невозможно - элемент уже используется.',
        byDefault: 'По умолчанию',
        others: 'Прочее',
        ok: 'Хорошо',
        filter: 'Фильтр',
        filters: 'Фильтры',
        apply: 'Применить',
        clear: 'Очистить',
        enterLocation: 'Введите местоположение'
    },
    subscribe: {
        successNewsTitle: 'Вы подписались на новости {name}',
        successNewsDescription: 'Оповещения о новых публикациях пользователя {name} будут приходить Вам на почту.',
    },
    lang: {
        ru: 'Русский',
        en: 'Английский',
        ka: 'Грузинский',
        ua: 'Украинский',
    },
    actions: {
        create: 'Создание',
        remove: 'Удаление',
        update: 'Редактирование',
    },
    settings: {
        articleModerationEnabled: {
            true: 'Активный',
            false: 'Неактивный',
        },
    },
    statuses: {
        allStatuses: 'Все публикации',
        published: 'Опубликованные',
        rejected: 'Отклоненные',
        draft: 'Черновики',
        moderation: 'На модерации',
    },
    roles: {
        ROLE_SUPER_ADMIN: 'Супер Администратор',
        ROLE_ADMIN: 'Администратор',
        ROLE_LDAP_USER: 'Сотрудник',
    },
    businessRoles: {
        ROLE_ADMIN: 'Администратор',
        super_admin: 'Супер Администратор',
        budget_admin: 'Администратор бюджетов',
        budget_owner: 'Владелец бюджетов',
        alternate: 'Заместитель',
        sub_budget_owner: 'Владелец подбюджета',
        hr_director: 'Директор по управлению персоналом',
        administrative_manager: 'Административный менеджер',
        accountant: 'Бухгалтер',
        hr: 'Отдел кадров',
        employee: 'Сотрудник',
        all: 'Все роли',
    },
    socialNetworks: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        twitter: 'Twitter',
        skype: 'Skype',
        telegram: 'Telegram',
        vk: 'ВКонтакте',
        ok: 'Одноклассники',
        strava: 'Strava',
    },
    leavePage: {
        question: 'Хотите покинуть страницу?',
        info: 'Все ваши изменения будут утрачены.',
    },
    page: {
        login: 'Авторизация',
        indexNews: 'Новости',
        viewNews: 'Новость',
        createNews: 'Создание новости',
        createBusinessTrip: 'Создание командировки',
        editNews: 'Редактирование новости',
        indexNewsAdmin: 'Новости',
        indexTopicAdmin: 'Категории новостей',
        viewTopicAdmin: 'Категория',
        createTopicAdmin: 'Создание категории',
        editTopicAdmin: 'Редактирование категории',
        viewProfile: 'Профиль',
        indexLogsAdmin: 'Логирование',
        indexBannerAdmin: 'Баннер',
        createBannerAdmin: 'Создание баннера',
        editBannerAdmin: 'Редактирование баннера',
        indexQuizAdmin: 'Опросы',
        indexUsersAdmin: 'Пользователи',
        indexSettingsAdmin: 'Настройки',
        knowledgeBase: 'Документы',
        indexPagesAdmin: 'Страницы',
        createPageAdmin: 'Создание страницы',
        editPageAdmin: 'Редактирование страницы',
        indexRegionAdmin: 'Регионы',
        indexDictionariesAdmin: 'Словари',
        indexSubmittingBudgets: 'Внесение бюджетов',
        greetingAdmin: 'Приветствие',
        events: 'События',
        createEvent: 'Создание события',
        editEvent: 'Редактирование события',
        editBusinessTrip: 'Редактирование заявки',
        indexSearch: 'Поиск',
        'about-company': 'О компании',
        'about-products': 'О продукции',
        'company-structure': 'Структура компании',
        collaboration: 'Совместная работа',
        welcome: 'Добро пожаловать!',
        404: '404',
        policy: 'Условия использования портала',
        viewBusinessTrips: 'Командировки',
    },
    subscribtions: {
        noUsers: 'На текущий момент вы не подписаны на своих коллег.',
    },
    error: {
        404: {
            title: 'Такой страницы не найдено :(',
            desc: 'Возможно она была удалена или изменила адрес',
            btn: 'перейти на главную',
        },
    },
    modal: {
        areYouSureToDelete: 'Вы уверены, что хотите удалить?',
        deletingTextModal: 'Удаление региона',
        areLongTime: 'Процесс может занять длительное время...',
    },
    indexNews: {
        new: 'Новость',
        likedNew: 'Не понравилось | Понравилось {n} человеку | Понравилось {n} людям | Понравилось {n} людям',
    },
    greeting: {
        show: 'Не показывать приветствие ',
        create: 'Создание приветствия',
        edit: 'Редактирование приветствия',
        preview: 'Предпросмотр приветствия',
        form: {
            image: {
                label: 'Загрузите обложку приветствия',
            },
            text: {
                label: 'Текст приветствия ',
                placeholder: 'Введите приветствие ',
            },
        },
    },
    createNews: {
        titleNew: 'Создание новости',
        titleEdit: 'Редактирование новости',
        preview: 'Предпросмотр новости',
        form: {
            title: 'Заглавие',
            subTitle: 'Подзаголовок',
            topic: 'Категория новости',
            image: 'Изображение',
            body: 'Cодержание',
            commentable: 'Разрешить комментировать новости',
            refuseReason: 'Причина',
            slug: 'Слаг',
        },
        placeholder: {
            title: 'Введите заглавие',
            subTitle: 'Введите подзаголовок',
            titleEn: 'Заглавие на локальном языке',
            text: 'Введите основной текст',
            topic: 'Выберите категорию',
            refuseReason: 'Введите причину',
            slug: 'Введите слаг',
        },
        uploadImageTitle: 'Загрузите обложку новости',
        uploadImageDesc: 'Допустимые форматы: JPEG, PNG. Максимальный размер: 8 МБ.<br/>Минимальное разрешение: {size}.',
        refuseReason: 'Причина отклонения',
    },
    viewNews: {
        isModerate: 'Новость проходит модерацию',
        shareNews: 'Поделиться новостью с коллегами',
        newsSent: 'Новость отправлена',
        statuses: {
            rejected: 'Новость не прошла модерацию',
            rejectedDesc: 'Ваша новость не прошла модерацию. Обратитесь к администратору.',
            rejectedSuccess: 'Новость успешно отклонена',
            draft: 'Новость в черновиках',
            draftDesc: 'Ваша новость не опубликована.',
            moderation: 'Новость на модерации',
            moderationDesc: 'Ваша новость добавлена в очередь на модерацию. Она появится на портале в случае, если одиин из модераторов ее одобрит.',
            published: 'Новость опубликована',
            publishedDesc: 'Ваша новость прошла модерацию и опубликована на портале.',
            waitModeration: 'После модерации новость опубликуют на портале.',
            savedToDrafts: 'Новость сохранена в черновики',
            offModeration: 'Выключить режим модерации?',
            offModerationDesc: 'Все новости, ожидающие модерации, будут автоматически опубликованы.',
        },
    },
    indexNewsAdmin: {
        name: 'Название',
        author: 'Автор',
        status: 'Статус',
    },
    indexTopicAdmin: {
        new: 'Категорию',
        name: 'Название',
        shared: 'Доступна для публикации',
    },
    indexRegionAdmin: {
        new: 'Регион',
        name: 'Название',
        regionExists: 'Такое название региона уже существует',
    },
    indexDictionariesAdmin: {
        tripPurposes: 'Цели поездки',
        currencies: 'Валюты',
        transportTypes: 'Типы транспорта',
        ticketClasses: 'Класс билета',
        services: 'Сервисы',
        currencyCode: 'Код  валюты',
        availableForPublish: 'Доступна для публикации',
        availableForSelection: 'Доступно для выбора',
    },
    indexSubmittingBudgets: {
        description: 'Внесите бюджеты/подбюджеты, курс валют и суточные',
        budgets: 'Бюджеты',
        reports: 'Отчеты',
        currencyRates: 'Курс валют',
        dailyAllowance: 'Суточные',
    },
    indexUsersAdmin: {
        name: 'Имя',
        role: 'Роль',
        roles: 'Роли',
        department: 'Департамент',
        alternates: 'Заместители',
        alternate: 'Заместитель',
        subBudgets: 'Владельцы подбюджета',
        subBudget: 'Владелец подбюджета',
        previewText: 'Присвойте активные роли по ключевым пользователям',
        roleAlreadyExist: 'Роль уже присвоена {value}',
        budgetOwnersAlternate: 'Заместитель у владельца бюджета',
        budgetOwnersSubBudgetOwner: 'Владелец подбюджета у владельца бюджета',
    },
    indexLogsAdmin: {
        date: 'Дата, время',
        entity: 'Сущность',
        name: 'Название',
        action: 'Действие',
        author: 'Автор',
        businessTripName: 'Название/№ заявки',
    },
    indexSettingsAdmin: {
        name: 'Имя',
        status: 'Статус',
    },
    indexBannerAdmin: {
        name: 'Название',
        status: 'Статус',
        statuses: {
            active: 'Активный',
            notActive: 'Неактивный',
        },
        new: 'Баннер',
    },
    indexQuizAdmin: {
        new: 'Опрос',
        dateAndTime: 'Дата, время',
        question: 'Вопрос',
        status: 'Статус',
    },
    createQuizAdmin: {
        creating: 'Создание опроса',
        editing: 'Редактирование опроса',
        form: {
            question: 'Вопрос',
            answer: 'Вариант ответа {number}',
            anonymous: 'Анонимный опрос',
        },
        placeholder: {
            question: 'Задайте вопрос',
            answer: 'Введите ответ',
        },
    },
    createBannerAdmin: {
        preview: 'Предпросмотр баннера',
        titleNew: 'Создание баннера',
        titleEdit: 'Редактирование баннера',
        titleCrop: 'Загрузка обложки',
        uploadImageTitle: 'Загрузите обложку баннера или баннер',
        uploadImageDesc: 'Допустимые форматы: JPEG, PNG. Максимальный размер: 8 МБ. Минимальное разрешение: 800х100.',
        form: {
            textBanner: 'Текст баннера',
            textColor: 'Цвет текста',
            textColorBanner: 'Цвет текста баннера',
            textBtnColor: 'Цвет текста кнопки',
            btnColor: 'Цвет кнопки',
            textBtn: 'Текст кнопки',
            link: 'Ссылка для кнопки',
            startDate: 'Старт публикации',
            timeStart: 'Время',
            endDate: 'Окончание публикации',
            timeEnd: 'Время',
        },
        placeholder: {
            textBanner: 'Введите основной текст баннера',
            textBtn: 'Введите текст кнопки',
            link: 'Вставте ссылку',
            startDate: 'Дата',
            timeStart: 'Время',
            endDate: 'Дата',
            timeEnd: 'Время',
        },
        danger: {
            startDate: 'Дата публикации',
            endDate: 'Дата окончания публикации',
        },
        activeMessage: 'Активным может быть только один баннер',
    },
    createTopicAdmin: {
        creating: 'Создание категории',
        editing: 'Редактирование категории',
        form: {
            name: 'Название категории',
            shared: 'Доступна для публикации',
        },
        placeholder: {
            name: 'Введите название категории',
        },
    },
    createRegionAdmin: {
        creating: 'Создание региона',
        editing: 'Редактирование региона',
        form: {
            name: 'Название региона',
            rawCountries: 'Названия стран',
        },
        placeholder: {
            name: 'Введите название региона',
        },
    },
    editPageAdmin: {
        title: 'Редактирование страницы',
        infoIcon: 'Загрузите иконку для страницы',
        subtitle: 'Подзаголовок на локальном языке',
        subtitleEn: 'Подзаголовок на английском языке',
        localTitle: 'Заглавие на локальном языке',
        englishTitle: 'Заглавие на английском языке',
    },
    createDictionariesAdmin: {
        title: {
            trip_purpose: 'Создание цели поездки',
            currencies: 'Создание валюты',
            transport: 'Создание типа транспорта',
            ticket: 'Создание класса билета',
            service: 'Создание сервиса',
        },
        label: {
            trip_purpose: 'Цель поездки',
            currencies: 'Валюта',
            transport: 'Тип транспорта',
            ticket: 'Класс билета',
            service: 'Сервис',
        },
        placeholder: 'Введите название',
        child: {
            transport: 'Класс билета',
        },
    },
    editDictionariesAdmin: {
        title: {
            trip_purpose: 'Редактирование цели поездки',
            currencies: 'Редактирование валюты',
            transport: 'Редактирование типа транспорта',
            ticket: 'Редактирование класса билета',
            service: 'Редактирование сервиса',
        },
    },
    createBudgetAdmin: {
        title: 'Создание бюджета',
        subBudgetTitle: 'Создание подбюджета',
        budget: 'Бюджет',
        subBudget: 'Подбюджет',
        budgetOwner: 'Владелец бюджета',
        subBudgetOwner: 'Владелец подбюджета',
        budgetSubbudgetName: 'Название бюджета/подбюджета',
        budgetName: 'Название бюджета',
        subBudgetName: 'Название подбюджета',
        defaultBudget: 'Бюджет по умолчанию',
        defaultSubBudget: 'Подбюджет по умолчанию',
        placeholder: {
            user: 'Выберете владельца бюджета',
            subBudgetOwner: 'Выберете владельца подбюджета',
            name: 'Введите название бюджета',
            subName: 'Введите название подбюджета',
            year: 'Выберете год',
        },
        createBudget: 'Создать бюджет',
        createSubBudget: 'Создать подбюджет',
        empty: 'Бюджет еще не создан',
        emptyByParams: 'По заданным параметрам ничего не найдено',
    },
    editBudgetAdmin: {
        title: 'Редактирование бюджета',
        subBudgetTitle: 'Редактирование подбюджета',
    },
    createCurrencyRate: {
        currencyRate: 'Курс валют',
        addRate: 'Добавить курс',
        placeholders: {
            date: 'Выберете дату',
        },
        empty: 'Курс валют еще не создан',
    },
    editDailyAllowance: {
        dailyAllowance: 'Суточные',
        dailyAllowanceSize: 'Размер суточных',
        travelDirection: 'Направление поездки',
        travelDirectionPlaceholder: 'Введите направление поездки',
        create: 'Создать суточные',
        creating: 'Создание суточных',
        editing: 'Редактирование суточных',
    },
    budgetReport: {
        total: 'Всего',
        used: 'Использованные',
        remaining: 'Остаток',
        owners: 'Владелец (-цы) бюджета/подбюджета',
        budget: 'Бюджет',
        sub_budget: 'Подбюджет',
        noBudgets: 'Бюджет еще не создан',
        allDepartments: 'Все департаменты',
        corporate: 'Корпоративный',
        finance: 'Финансы',
        spent: 'Потрачено',
        amountPending: 'Сумма ($) на рассмотрении',
    },
    businessTrips: {
        faq: 'Руководство для функционала {link}',
        faqText: '«Командировки»',
        transfer: 'Трансфер',
        route: 'Маршрут',
        created: 'Создана',
        title: 'Командировки',
        allTrips: 'Все заявки',
        daysCount: 'Количество дней',
        myTrips: 'Мои заявки',
        trip: 'Заявка',
        trips: 'Заявки',
        approved: 'Утвержденные',
        inProcessing: 'В обработке',
        completed: 'Завершенные',
        budgets: 'Бюджеты',
        reportsByEmployee: 'Отчеты по сотрудникам',
        number: 'Номер',
        status: 'Статус',
        employeeOrNot: 'Сотрудник / Консультант',
        tripPurpose: 'Цель поездки',
        destination: 'Пункт назначения',
        startDateToEndDate: 'Дата старта/ окончания',
        plan: 'План',
        fact: 'Факт',
        planSum: 'Сумма ({currencySymbol}) план',
        factSum: 'Сумма ({currencySymbol}) факт',
        filters: {
            startDate: 'Дата старта',
            endDate: 'Дата окончания',
            createdDate: 'Дата создания',
        },
        deleteReason: 'Причина удаления',
        roleStatuses: {
            approved: 'Заявка утверждена',
            pending: 'Заявка на утверждении',
            rejected: 'Заявка отклонена',
        },
        factSpent: 'Фактические затраты',
        pinnedEmployees: 'Закрепленные сотрудники',
        viewFrom: 'Вы просматриваете страницу от ',
        fromAllBudgetOwners: 'Всех владельцев бюджета',
        inputAccountantExpensesReason: 'Введите причину превышения фактических затрат',
    },
    createBusinessTrip: {
        title: 'Создание командировки',
        employee: 'Сотрудник',
        notEmployee: 'Консультант',
        fio: 'ФИО',
        startBusinessTrip: 'Начало командировки',
        endBusinessTrip: 'Конец командировки',
        dayCounts: 'Количество дней: {count}',
        additional: 'Дополнительно',
        transferAirport: 'Трансфер в/из аэропорт(а)',
        transferOffice: 'Трансфер в/из офиса или другого пункта',
        datePlaceTime: 'Дата, место и время',
        transportType: 'Тип транспорта',
        cityAndCountry: 'Город, страна',
        country: 'Страна',
        city: 'Город',
        placeholder: {
            fio: 'Введите ФИО',
            user: 'Выберете сотрудника',
            transportType: 'Выберите тип транспорта',
            ticketType: 'Выберите класс билета',
            additional: 'Выберите дополнительные сервисы',
            transferAirport: 'Укажите дату, место и время',
            otherInfo: 'Пожелания по поездке',
        },
        errors: {
            cantCreateTrip: 'Невозможно создать командировку',
            notFoundRoleAndBudgetCount: 'Для Вашего бизнес юнита не был назначен {roles} и не был выделен бюджет. Обратитесь, пожалуйста, к Вашему руководителю.',
            not_found_role: 'Для Вашего бизнес юнита не был назначен {roles}. Обратитесь, пожалуйста, к Вашему руководителю.',
            budget_count: 'Для Вашего бизнес юнита не был выделен бюджет. Обратитесь, пожалуйста, к Вашему руководителю.',
        },
        empty: 'У Вас ещё не было командировок',
        emptyDesc: 'Создайте заявку на командировку',
        arrange: 'Оформить',
        successGoToApprove: 'Заявка {number} отправлена в обработку.',
        successGoToReApprove: 'Заявка {number} отправлена на повторное утверждение.',
        successClosed: 'Заявка {number} закрыта.'
    },
    editBusinessTrip: {
        title: 'Редактирование командировки',
        request: 'Заявка {number}',
        budgetOwnerForApprove: 'Владелец бюджета/подбюджета',
        budget: 'Название бюджета/подбюджета',
        hotelPrice: 'Стоимость проживания',
        transportPrice: 'Стоимость транспорта',
        transferPrice: 'Стоимость трансфера',
        dailyPayment: 'Суточные',
        otherExpenses: 'Другое',
        otherExpensesDestination: 'Назначение суммы "Другое"',
        total: 'Всего',
        placeholder: {
            budgetOwnerForApprove: 'Выберете из списка',
            budget: 'Выберете из списка',
        },
        reApproval: 'Повторное утверждение',
        reApprovalDesc: 'Отправить заявку на повторное утверждение?',
        wishes: 'Пожелания',
        accommodation: 'Проживание',
        transport: 'Транспорт',
        transfer: 'Трансфер',
        uploadDocumentsTickets: 'Загрузите документы, билеты'
    },
    businessTripStatuses: {
        created: 'В обработке',
        wait_approval: 'Ожидает утверждение',
        approved: 'Утверждена',
        partially_approved: 'Частично утверждена',
        completed: 'Оформлена',
        closed: 'Закрыта',
        decline: 'Отклонена',
    },
    profile: {
        information: 'Информация',
        articlesMy: 'Мои публикации',
        articles: 'Публикации',
        birthday: 'День рождения',
        birthdaysModal: 'Дни рождения',
        country: 'Страна',
        city: 'город',
        businessUnit: 'Бизнес-юнит',
        company: 'Компания',
        socialProfiles: 'Социальные профили',
        contactInfo: 'Контактная информация',
        phone: 'Телефон',
        mail: 'Email',
        workAddress: 'Адрес работы',
        career: 'Карьера',
        joinedToTeam: 'Присоединился(-лась) к команде IDS BORJOMI',
        leader: 'Руководитель',
        personalInfo: 'Личная информация',
        language: 'Владение языками',
        certificationsAndSkills: 'Сертификации и дополнительные навыки',
        workExperience: 'Опыт работы до IDS BORJOMI',
        hobbies: 'Увлечения',
        functional: 'Функционал',
        removedText: 'В данный момент этот сотрудник не работает в компании',
    },
    profileSettings: {
        title: 'Настройки профиля',
        mainInformation: 'Основная информация',
        socialProfiles: 'Социальные профили',
        alerts: 'Оповещения',
        upload: 'Загрузить фото',
        receiveAlerts: 'Получение уведомлений на почтовый ящик',
        form: {
            avatar: 'Фото',
            workAddress: 'Адрес работы',
            languages: 'Владение языками',
            additionalSkills: 'Сертификации и дополнительные навыки',
            prevExperience: 'Опыт работы до IDS BORJOMI',
            hobbies: 'Увлечения',
            social: 'Соц. сеть',
            link: 'Ссылка',
            functional: 'Функционал',
            locale: 'Язык портала',
            receivePublicationMessage: 'Ответы на мои публикации',
            receiveBirthdayMessage: 'Поздравления с днем рождения',
        },
        placeholder: {
            workAddress: 'Введите адрес',
            languages: 'Языки',
            additionalSkills: 'Напишите о своих сертификациях и навыках',
            prevExperience: 'Опыт работы',
            hobbies: 'Напишите о своих увлечениях',
            social: 'Выберите соц. сеть',
            link: 'Вставьте ссылку',
            functional: 'Опишите какие обязаности вы выполняете',
        },
    },
    staffSearch: {
        name: 'Имя',
        phone: 'Телефон',
        email: 'Почта',
    },
    search: {
        title: 'Результаты поиска',
        desc: 'По запросу <span class="font-weight-bold">{search}</span> найдено <span class="font-weight-bold">{count}</span> результатов',
        articles: 'Новости',
        staff: 'Сотрудники',
        medias: 'Документы',
        events: 'События',
        empty: {
            title: 'Поиск не дал результатов',
            desc: 'Попробуйте использовать другие ключевые слова',
        },
    },
    auth: {
        auth: 'Авторизация',
        authDesc: 'Чтобы войти на портал, заполните поля ниже.',
        authConfirm: 'Проверьте свою почту',
        authConfirmDesc: 'Мы только что отправили шестизначный код на адрес <span class="font-weight-boldest">{email}</span>. Не закрывайте это окно, пока проверяете свой почтовый ящик, а затем введите приведенный ниже код.',
        form: {
            email: 'Email',
            password: 'Пароль',
            code: 'Код',
        },
        iAccept: 'Я принимаю',
        termsOfUseOfThePortal: 'правила пользования порталом',
        signIn: 'Войти',
        didtReceiveLetter: 'Не пришло письмо?',
        sendCodeAgain: 'Отправить код еще раз',
        codeSent: 'Ваш код отправлен повторно.',
        error: {
            invalidCredentials: 'Логин или пароль введен неверно',
            blockAccaount: 'Ваш аккаунт заблокирован. Чтобы восстановить доступ, обратитесь к админу',
            accountUnlock: 'Пользователь разблокирован',
            invalidCode: 'Вы ввели неверный код. Попробуйте еще раз.',
        },
    },
    validation: {
        invalid: '{name} не является допустимым',
        required: '{name} обязательное поле',
        requiredIf: '{name} обязательное поле',
        greaterThan: 'Должно быть больше {value}',
        lessThan: 'Максимум {value}',
        requiredDays: 'Выберите дни для настройки повторения',
        alphaNum: '{name} должен быть на английском языке и без пробелов',
        alphaNumCustom: '{name} должен быть на английском языке и без пробелов',
        variant: 'Обязательное поле',
        url: '{name} должна быть url адресом',
        imageFormat: 'Неверный формат файла. Допустимые формат: {format}',
        imageWeight: 'Файл слишком большой. Максимальный размер: {weight} MB',
        imageSize: 'Неверное разрешение файла. Минимальное разрешение: {size}',
        imageMaxCount: 'Максимальное кол-во загружаемых файлов - {count}',
        fileNameMaxCount: 'Максимальное количество символов в названии файла - {count}',
        fileMinWeight: 'Минимальный размер файла: {size} KB',
        someFileAlreadyExists: 'Один из файлов уже существует в системе',
        isAfter: '{name} должна быть после даты старта',
        maxLength: 'Максимальное количество символов - {count}',
        maxValue: 'Максимальное значение - {val}',
        fileType: 'Загружаемый формат файла не поддерживается системой',
    },
    quiz: {
        quiz: 'Опрос',
        startQuiz: 'Пройти опрос',
        selected: 'Ответ выбрали',
        results: 'Результаты опроса',
        variant: 'Один из вариантов',
        publicResults: 'Результаты',
        voting: 'Голосование',
        activeMessage: 'Активным может быть только один опрос',
        thankYou: 'Спасибо за прохождение опроса',
        statuses: {
            active: 'Активный',
            notActive: 'Неактивный',
        },
        peopleVote: 'Проголосовало 0 человек | Проголосовал {n} человек | Проголосовали {n} человек | Проголосовал {n} человек',
        peopleSelected: 'Выбрало 0 человек | Выбрал {n} человек | Выбрали {n} человек | Выбрал {n} человек',
    },
    calendar: {
        birthdayDisabledText: 'Этот пользователь установил ограничение на отправку поздравлений',
        toEvents: 'К событиям',
        eventText: 'Событие',
        createEvent: 'Создание события',
        editEvent: 'Редактирование события',
        removeEvent: 'Подтвердить удаление',
        removeEventAlert: 'Событие удалено',
        birthdays: 'Дни рождения',
        units: 'Бизнес-юниты',
        year: 'Год',
        month: 'Месяц',
        week: 'Неделя',
        day: 'День',
        today: 'Сегодня',
        none: 'Не повторяется',
        typeEvent: 'Мероприятие',
        typeInfo: 'Информационное',
        showAllUsers: 'Показать всех участников',
        participating: 'Участвуете?',
        wantToJoin: 'Хотите принять участие?',
        successJoinMessage: 'Запрос на присоединение отправлен организатору',
        allParticipants: 'Все участники события',
        participant: '0 участников | {n} участник | Выбрали {n} участника | Выбрал {n} участников',
        // birthdayMan: '0 именинников | {n} именинник | Выбрали {n} именинника | Выбрал {n} именинников',
        birthdayMan: '0 именинников | {n} именинник | {n} именинника | {n} именинников',
        inputCongratulation: 'Введите поздравление',
        repeatAt: 'Повторять кажд.',
        onSuchDays: 'в такие дни:',
        congratulationWasSend: 'Поздравление отправлено!',
        event: {
            title: {
                label: 'Название события',
                placeholder: 'Название события',
            },
            startDate: {
                label: 'Дата старта события',
            },
            endDate: {
                label: 'Дата окончания события',
            },
            periodicity: {
                label: 'Периодичность',
            },
            type: {
                label: 'Тип события',
            },
            link: {
                label: 'Cсылка на событие',
            },
            users: {
                label: 'Участники',
            },
            invited: {
                label: 'Приглашенные',
            },
            description: {
                label: 'Описание',
                placeholder: 'Описание события',
            },
        },
        days: {
            monday: 'пн',
            tuesday: 'вт',
            wednesday: 'ср',
            thursday: 'чт',
            friday: 'пт',
            saturday: 'сб',
            sunday: 'вс',
        },
        finish: 'Завершить',
        repeatSettings: 'Настройки повторения',
        organizer: 'Организатор',
    },
    ie: {
        header: 'К сожалению, ваш браузер больше не поддерживается',
        text: 'Для использования Интранет-портала IDS Borjomi, установите другой браузер.',
    },
    knowledgeBase: {
        folderBase: 'папку',
        fileBase: 'файл',
        createFolder: 'Создание папки',
        editFolder: 'Редактирование папки',
        toCreateFolder: 'Создать папку',
        toEditFolder: 'Редактировать папку',
        title: 'Название',
        author: 'Автор',
        createdAt: 'Дата создания',
        size: 'Размер',
        folder: {
            name: {
                label: 'Название папки',
                placeholder: 'Введите название папки',
            },
            description: {
                label: 'Описание',
                placeholder: 'Введите описание папки',
            },
            author: 'Автор',
            createdAt: 'Дата создания',
            size: 'Размер',
        },
        emptyRootTitle: 'Страница "Документы" пуста',
        emptyRootDescription: 'Создайте папку',
        emptyTitle: 'Эта папка пуста',
        emptyDescription: 'Перетащите файлы в это окно, чтобы загрузить их.',
        uploadIcon: 'Загрузите иконку для папки',
    },
    bookmarks: {
        myLinks: 'Мои ссылки',
        addLink: 'Добавление ссылки',
        editLink: 'Редактирование ссылки',
        toAddLink: 'Добавить ссылку',
        link: {
            title: {
                label: 'Название ссылки',
                placeholder: 'Введите название ссылки',
            },
            url: {
                label: 'URL - адрес',
                placeholder: 'http://',
            },
        },
    },
    policy: {
        h1: 'Правила пользования порталом',
        p1: 'Дорогой коллега, приветствуем тебя на',
        p1_1: 'Интранет-портале! ',
        p2: 'Здесь мы, все сотрудники IDS Borjomi, можем узнавать все о нашей Группе и ее стратегии, делиться новостями, получать доступ к актуальной информации и сервисам, которые помогают в работе.',
        p3: 'Заходя на портал, ты соглашаешься придерживаться следующих правил:',
        li1: 'соблюдать этику общения и уважать чужое мнение;',
        li2: 'оставлять за пределами портала обсуждение острых социальных тем, личных вопросов, включая деньги и здоровье;',
        li3: 'придерживаться принципа прозрачности и отсутствия анонимности в публикации информации;',
        li4: 'помнить о том, что портал предназначен исключительно для внутреннего использования сотрудников, то есть содержимое является конфиденциальным; ',
        li5: 'писать грамотно;',
        li6: 'быть готовым к тому, что администрация портала может отказать в публикации или удалить контент на портале, если он нарушает любое озвученное правило.',
        p4: 'На',
        p4_1: ' неприемлемо',
        li7: 'использовать ненормативную лексику, грубые выражения, переходить на личности, оскорблять национальные, религиозные и нравственные чувства;',
        li8: 'публиковать заведомо ложную информацию;',
        li9: 'публиковать ссылки на нелицензионное коммерческое программное обеспечение, программы для его взлома и генераторы ключей, а также материалы, защищенные авторскими правами; ',
        li10: 'выступать в роли модератора: делать замечания другим пользователям без соответствующих полномочий;',
        li11: 'обсуждать и осуждать действия пользователей, модераторов и администрации.',
        p5: 'За нарушение правил администраторы могут вынести предупреждение и заблокировать аккаунт, но нам бы этого не хотелось.',
        p6: 'По всем техническим вопросам к IT Service Desk',
        p7: 'по вопросам контента на Ask IDS Borjomi',
        p8: 'Пусть',
        p8_1: 'приносит только пользу в работе и коммуникациях!',
        p9: 'Команда внутренних коммуникаций',
    },
}

export default locale
