import news from "./news";
import topic from "./topic";
import banner from "./banner";
import logs from "./logs";
import quiz from "./quiz";
import users from "./users";
import settings from "./settings";
import pages from "./pages";
import region from "./region";
import greeting from "./greeting";
import dictionaries from "./dictionaries"
import budgets from "./budgets"

const routers = [
  {
    path: '/admin',
    redirect: '/admin/news',
    component: () => import('@/layouts/LayoutAdmin.vue'),
    children: [
      ...news,
      ...topic,
      ...banner,
      ...quiz,
      ...users,
      ...settings,
      ...logs,
      ...pages,
      ...region,
      ...greeting,
      ...dictionaries,
      ...budgets,
    ],
  },
]
export default routers
