<template>
  <b-modal
      v-model="getValue"
      hide-footer
      hide-header
      v-bind="$attrs"
      v-on:change="$emit('change', $event)">
    <template #default="{ close }">
      <div class="bmodal">
        <div v-if="!hideClose" class="bmodal-close">
          <div
              class="btn btn-xs btn-icon btn-circle"
              @click="close">
            <span class="svg-icon">
               <inline-svg src="/media/svg/icons/General/Close.svg"/>
            </span>
          </div>
        </div>
        <div class="bmodal-title">
          <slot name="title"></slot>
        </div>
        <div v-if="hasBodySlot" class="bmodal-body mt-7">
          <slot name="body"></slot>
        </div>
        <div v-if="hasFooterSlot" class="bmodal-footer mt-7">
          <slot name="footer"></slot>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'BModalUI',
    props: {
      value: null,
      hideClose: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      getValue: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
      hasBodySlot () {
        return !!this.$slots['body']
      },
      hasFooterSlot () {
        return !!this.$slots['footer']
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bmodal{
    padding: 36px 0;
    position: relative;
    &-close{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
</style>
