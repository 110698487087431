export const locale = {
	base: {
		general: 'გენერალი',
		aprove: 'ადასტურება',
		subscribe: 'გამოწერა',
		unsubscribe: 'გამოწერის გაუქმება',
		toSubscriptions: 'ხელმოწერებზე',
		subscribtions: 'ხელმოწერებზე',
		colleagues: 'კოლეგები',
		search: 'ძებნა',
		searchMain: 'სიახლეების, თანამშრომლების, მოვლენების ძებნა',
		emptyList: 'ცარიელი სია',
		publish: 'გამოქვეყნება',
		unlock: 'ბლოკის მოხსნა',
		published: 'გამოქვეყნება',
		rejected: 'უარყოფა',
		send: 'გაგზავნა',
		saveAsDraft: 'მონახაზის სახით შენახვა',
		save: 'შენახვა',
		select: 'არჩევა',
		noData: 'მონაცემები არ მოიპოვება',
		toMain: 'მთავარზე',
		myArticles: 'ჩემი პუბლიკაციები',
		myDrafts: 'ჩემი მონახაზები ',
		exit: 'გასვლა',
		edit: 'რედაქტირება',
		delete: 'წაშლა',
		close: 'დახურვა',
		leave: 'გასვლა',
		more: 'მეტი',
		add: 'დამატება',
		showMore: 'მეტის ჩვენება',
		hideMore: 'დამალვა',
		answer: '0 პასუხი | {n} პასუხი | {n} პასუხი | {n} პასუხი',
		category: '0 კატეგორია | {n} კატეგორია | {n} კატეგორია | {n} კატეგორია',
		clustery: '0 რეგიონი | {n} რეგიონი | {n} რეგიონი | {n} რეგიონი',
		categories: 'კატეგორია',
		clusters: 'რეგიონები',
		clusterOne: 'რეგიონი',
		categoryOne: 'კატეგორია',
		answered: 'პასუხგაცემულია',
		upload: 'ატვირთვა',
		uploadFile: 'ფაილის ატვირთვა',
		uploadImg: 'გამოსახულების ატვირთვა',
		supportedFormats: 'დასაშვები ფორმატები: {value}.',
		supportedSize: 'დასაშვები ზომა: {value}.',
		fileUploaded: 'ფაილი ატვირთულია',
		yes: 'დიახ',
		no: 'არა',
		maybe: 'შესაძლებელია',
		join: 'შემოგვიერთდი',
		tune: 'პარამეტრები',
		result: 'შედეგი',
		congratulate: 'მილოცვა',
		congratulation: 'მილოცვა',
		actions: 'მოქმედება',
		commentOrigin: 'კომენტარი',
		writeComment: 'კომენტარის დაწერა..',
		department: 'დეპარტამენტი',
		create: 'შექმნა',
		articleModerationEnabled: 'სიახლეების მოდერაცია',
		cancel: 'გაუქმება',
		comment: '0 კომენტარი | {n} კომენტარი | {n} კომენტარი | {n} კომენტარი',
		seeComments: 'სხვა კომენტარების ჩვენება',
		reply: 'პასუხი',
		myProfile: 'ჩემი პროფილი',
		profileSetting: 'პროფილის პარამეტრები',
		companyStructure: 'კომპანიის სტრუქტურა',
		businessTrips: 'Ბიზნეს მოგზაურობა',
		portal: 'პორტალი',
		adminPanel: 'ადმინ-პანელი',
		never: 'არასოდეს',
		date: 'თარიღი',
		time: 'დრო',
		after: 'შემდეგ',
		liked: 'მოწონებული',
		selectDate: 'თარიღის არჩევა',
		times: '-ჯერ',
		subTitleDocuments: 'სუბტიტრები დოკუმენტებისთვის',
		subTitle: 'ქვესათაური',
		sizes: ['ბაიტი', 'კბ', 'მბ', 'გბ'],
		cluster: 'რეგიონი',
		timezoneInfo: 'დრო აისახება სასაათო სარტყელში Europe GMT +3:00',
		saveData: 'მონაცემები წარმატებით შეინახა',
		icons: 'პიქტოგრამები',
		share: 'გაზიარება',
		enterName: 'შეიყვანეთ სათაური',
		sum: 'ჯამი',
		currency: 'ვალუტა',
		rate: 'კარგად',
		noItemsByParams: 'მითითებულ პარამეტრებზე შედეგი ვერ მოიძებნა.',
		deleteNotAvailable: 'შეუძლებელია წაშლა - ელემენტი უკვე გამოიყენება.',
		byDefault: 'ნაგულისხმევი',
		others: 'სხვა',
		ok: 'კარგი',
		filter: 'ფილტრი',
		filters: 'ფილტრები',
		apply: 'მიმართეთ',
		clear: 'წმინდა',
		enterLocation: 'შეიყვანეთ ადგილმდებარეობა'
	},
	subscribe: {
		successNewsTitle: 'თქვენ გამოიწერეთ ახალი ამბები {name}',
		successNewsDescription: 'შეტყობინებები ახალი მომხმარებლის შესახებ {name} შეტყობინებები გაიგზავნება თქვენს ელ.',
	},
	lang: {
		ru: 'რუსული',
		en: 'ინგლისური',
		ka: 'ქართული',
		ua: 'უკრაინული',
	},
	actions: {
		create: 'შექმნა',
		remove: 'წაშლა',
		update: 'რედაქტირება',
	},
	settings: {
		articleModerationEnabled: {
			true: 'აქტიური',
			false: 'არააქტიური',
		},
	},
	statuses: {
		allStatuses: 'ყველა პუბლიკაცია',
		published: 'გამოქვეყნებული',
		rejected: 'უარყოფილი',
		draft: 'მონახაზი',
		moderation: 'მოდერაციაზე',
	},
	roles: {
		ROLE_SUPER_ADMIN: 'სუპერ ადმინისტრატორი',
		ROLE_ADMIN: 'ადმინისტრატორი',
		ROLE_LDAP_USER: 'თანამშრომელი',
	},
	businessRoles: {
		ROLE_ADMIN: 'ადმინისტრატორი',
		super_admin: 'სუპერ ადმინისტრატორი',
		budget_admin: 'ბიუჯეტის ადმინისტრატორი',
		budget_owner: 'ბიუჯეტის მფლობელი',
		alternate: 'მოადგილე',
		sub_budget_owner: 'ქვებიუჯეტის მფლობელი',
		hr_director: 'Ადამიანური რესურსების დირექტორი',
		administrative_manager: 'Ადმინისტრაციული მენეჯერი',
		accountant: 'ბუღალტერი',
		hr: 'ადამიანური რესურსების დეპარტამენტი',
		employee: 'თანამშრომელი',
		all: 'ყველა როლი',
	},
	socialNetworks: {
		facebook: 'Facebook',
		instagram: 'Instagram',
		linkedin: 'LinkedIn',
		twitter: 'Twitter',
		skype: 'Skype',
		telegram: 'Telegram',
		vk: 'ვკონტაქტე',
		ok: 'ოდნოკლასნიკი',
		strava: 'Strava',
	},
	leavePage: {
		question: 'გსურთ გახვიდეთ გვერდიდან?',
		info: 'თქვენი ყველა ცვლილება დაიკარგება',
	},
	page: {
		login: 'ავტორიზაცია',
		indexNews: 'სიახლეები',
		viewNews: 'სიახლე',
		createNews: 'სიახლის შექმნა',
		createBusinessTrip: 'შექმენით საქმიანი მოგზაურობა',
		editNews: 'სიახლის რედაქტირება',
		indexNewsAdmin: 'სიახლეები',
		indexTopicAdmin: 'სიახლეების კატეგორია',
		viewTopicAdmin: 'კატეგორია',
		createTopicAdmin: 'კატეგორიის შექმნა',
		editTopicAdmin: 'კატეგორიის რედაქტირება',
		viewProfile: 'პროფილი',
		indexLogsAdmin: 'ლოგირება',
		indexBannerAdmin: 'ბანერი',
		createBannerAdmin: 'ბანერის შექმნა',
		editBannerAdmin: 'ბანერის რედაქტირება',
		indexQuizAdmin: 'კითხვარები',
		indexUsersAdmin: 'მომხმარებლები',
		indexSettingsAdmin: 'პარამეტრები',
		knowledgeBase: 'დოკუმენტები',
		indexPagesAdmin: 'გვერდები',
		createPageAdmin: 'გვერდის შექმნა',
		editPageAdmin: 'გვერდის რედაქტირება',
		indexRegionAdmin: 'რეგიონები',
		indexDictionariesAdmin: 'ლექსიკონები',
		indexSubmittingBudgets: 'ბიუჯეტების წარდგენა',
		greetingAdmin: 'მისალმება',
		events: 'ღონისძიებები',
		createEvent: 'ღონისძიების შექმნა',
		editEvent: 'ღონისძიების რედაქტირება',
		editBusinessTrip: 'აპლიკაციის რედაქტირება',
		indexSearch: 'ძებნა',
		'about-company': 'კომპანიის შესახებ',
		'about-products': 'პროდუქციის შესახებ',
		'company-structure': 'კომპანიის სტრუქტურა',
		collaboration: 'კოლაბორაცია',
		welcome: 'კეთილი იყოს თქვენი მობრძანება',
		404: '404',
		policy: 'პორტალის გამოყენების პირობები',
		viewBusinessTrips: 'Ბიზნეს მოგზაურობა',
	},
	subscribtions: {
		noUsers: 'თქვენ ამჟამად არ ხართ გამოწერილი თქვენს კოლეგებზე.',
	},
	error: {
		404: {
			title: 'ასეთი გვერდი ვერ მოიძებნა:(',
			desc: 'გვერდი შესაძლოა წაიშალა ან შეიცვალა მისამართი',
			btn: 'მთავარზე გადასვლა',
		},
	},
	modal: {
		areYouSureToDelete: 'დარწმუნებული ხართ, რომ გსურთ წაშლა?',
		deletingTextModal: 'რეგიონის წაშლა',
		areLongTime: 'პროცესს შესაძლოა დიდი დრო დასჭირდეს..',
	},
	indexNews: {
		new: 'სიახლე',
		likedNew:'არ მოეწონა | მოეწონა {n} ადამიანს | მოეწონა {n} ადამიანს | მოეწონა {n} ადამიანს '
	},
	greeting: {
		show: 'მისალმების დამალვა ',
		create: 'მისალმების შექმნა',
		edit: 'მისალმების რედაქტირება',
		preview: 'მისალმების გადახედვა',
		form: {
			image: {
				label: 'მისალმების ფონი',
			},
			text: {
				label: 'მისალმების ტექსტი ',
				placeholder: 'ჩაწერეთ მისალმება',
			},
		},
	},
	createNews: {
		titleNew: 'სიახლის შექმნა',
		titleEdit: 'სიახლის რედაქტირება',
		preview: 'სიახლის გადახედვა',
		form: {
			title: 'სათაური',
			subTitle: 'ქვესათაური',
			topic: 'სიახლის კატეგორია',
			image: 'გამოსახულება',
			body: 'შინაარსი',
			commentable: 'სიახლის კომენტირების შესაძლებლობა',
			refuseReason: 'მიზეზი',
			slug: 'სლაგი',
		},
		placeholder: {
			title: 'შეიტანეთ სათაური',
			subTitle: 'შეიტანეთ ქვესათაური',
			titleEn: 'სათაური ადგილობრივ ენაზე',
			text: 'შეიყვანეთ ძირითადი ტექსტი',
			topic: 'შეიყვანეთ კატეგორია',
			refuseReason: 'შეიყვანეთ მიზეზი',
			slug: 'შეიყვანეთ სლაგი',
		},
		uploadImageTitle: 'ატვირთეთ სიახლის ფონი',
		uploadImageDesc:'დასაშვები ფორმატები: JPEG, PNG. მაქსიმალური ზომა: 8 მბ.<br/>მინიმალური გარჩევადობა: {size}.',
		refuseReason: 'უარყოფის მიზეზი',
	},
	viewNews: {
		isModerate: 'სიახლე გადის მოდერაციას',
		shareNews: 'სიახლის გაზიარება კოლეგებთან ერთად',
		newsSent: 'სიახლე გაიგზავნა',
		statuses: {
			rejected: 'სიახლემ ვერ გაიარა მოდერაცია',
			rejectedDesc: 'თქვენმა სიახლემ მოდერაცია ვერ გაიარა. მიმართეთ ადმინისტრატორს.',
			rejectedSuccess: 'სიახლე უარყოფილია წარმატებით',
			draft: 'სიახლე მონახაზებშია',
			draftDesc: 'თქვენი სიახლე არ გამოქვეყნდა.',
			moderation: 'სიახლე გადის მოდერაციას',
			moderationDesc: 'თქვენი სიახლე მოდერაციის მოლოდინშია. იგი გამოჩნდება პორტალზე თუ ერთ-ერთი მოდერატორი მოიწონებს.',
			published: 'სიახლე გამოქვეყნდა',
			publishedDesc: 'თქვენმა სიახლემ გაიარა მოდერაცია და გამოქვეყნდა პორტალზე.',
			waitModeration: 'მოდერაციის შემდეგ სიახლე გამოქვეყნდება პორტალზე.',
			savedToDrafts: 'სიახლე შენახულია მონახაზებში',
			offModeration: 'მოდერაციის რეჟიმის გამორთვა?',
			offModerationDesc: 'მოდერაციის მოლოდინში მყოფი ყველა სიახლე ავტომატურად გამოქვეყნდება.',
		},
	},
	indexNewsAdmin: {
		name: 'სახელი',
		author: 'ავტორი',
		status: 'სტატუსი',
	},
	indexTopicAdmin: {
		new: 'კატეგორია',
		name: 'სახელი',
		shared: 'ხელმისაწვდომია გამოქვეყნებისთვის',
	},
	indexRegionAdmin: {
		new: 'რეგიონი',
		name: 'სახელი',
		regionExists: 'ასეთი სახელის რეგიონი უკვე არსებობს',
	},
	indexDictionariesAdmin: {
		tripPurposes: 'მოგზაურობის მიზანი',
		currencies: 'ვალუტები',
		transportTypes: 'ტრანსპორტის ტიპები',
		ticketClasses: 'ბილეთების კლასი',
		services: 'სერვისები',
		currencyCode: 'ვალუტები',
		availableForPublish: 'ხელმისაწვდომია გამოსაცემად',
		availableForSelection: 'ხელმისაწვდომია შერჩევისთვის',
	},
	indexSubmittingBudgets: {
		description: 'შეიყვანეთ ბიუჯეტები / ქვებიუჯეტები, გაცვლითი კურსი და დღიური',
		budgets: 'ბიუჯეტები',
		reports: 'ანგარიშები',
		currencyRates: 'გაცვლითი კურსი',
		dailyAllowance: 'დღიური შემწეობა',
	},
	indexUsersAdmin: {
		name: 'სახელი',
		role: 'როლი',
		roles: 'როლები',
		department: 'დეპარტამენტი',
		alternates: 'მოადგილე',
		alternate: 'მოადგილე',
		subBudgets: 'ქვებიუჯეტის მფლობელები',
		subBudget: 'ქვებიუჯეტის მფლობელი',
		previewText: 'მიანიჭეთ აქტიური როლები ძირითადი მომხმარებლების მიერ',
		roleAlreadyExist: 'როლი უკვე დანიშნულია {value}',
		budgetOwnersAlternate: 'ბიუჯეტის მფლობელის მოადგილე',
		budgetOwnersSubBudgetOwner: 'ქვებიუჯეტის მფლობელი ბიუჯეტის მფლობელთან',
	},
	indexLogsAdmin: {
		date: 'დრო, თარიღი',
		entity: 'არსი',
		name: 'სახელი',
		action: 'მოქმედება',
		author: 'ავტორი',
		businessTripName: 'სათაური / № აპლიკაციები',
	},
	indexSettingsAdmin: {
		name: 'სახელი',
		status: 'სტატუსი',
	},
	indexBannerAdmin: {
		name: 'სახელი',
		status: 'სტატუსი',
		statuses: {
			active: 'აქტიური',
			notActive: 'არააქტიური',
		},
		new: 'ბანერი',
	},
	indexQuizAdmin: {
		new: 'გამოკითხვა',
		dateAndTime: 'თარიღი, დრო',
		question: 'კითხვა',
		status: 'სტატუსი',
	},
	createQuizAdmin: {
		creating: 'კითხვარის შექმნა',
		editing: 'კითხვარის რედაქტირება',
		form: {
			question: 'კითხვა',
			answer: 'პასუხის ვარიანტი {number}',
			anonymous: 'ანონიმური გამოკითხვა',
		},
		placeholder: {
			question: 'დასვით შეკითხვა',
			answer: 'გაეცით პასუხი',
		},
	},
	createBannerAdmin: {
		preview: 'ბანერის დათვალიერება',
		titleNew: 'ბანერის შექმნა',
		titleEdit: 'ბანერის რედაქტირება',
		titleCrop: 'ფონის ჩატვირთვა',
		uploadImageTitle: 'ჩატვირთეთ ბანერი ან ბანერის ფონი',
		uploadImageDesc: 'დასაშვები ფორმატები: JPEG, PNG. მაქსიმალური ზომა: 8 МБ. მინიმალური ზომა: 800х100.',
		form: {
			textBanner: 'ბანერის ტექსტი',
			textColor: 'ტექსტის ფერი',
			textColorBanner: 'ბანერის ტექსტის ფერი',
			textBtnColor: 'ღილაკის ტექსტის ფერი',
			btnColor: 'ღილაკის ფერი',
			textBtn: 'ღილაკის ტექსტი',
			link: 'ღილაკის ბმული',
			startDate: 'პუბლიკაციის გამოქვეყნების თარიღი',
			timeStart: 'დრო',
			endDate: 'პუბლიკაციის გამოქვეყნების დასრულების თარიღი',
			timeEnd: 'დრო',
		},
		placeholder: {
			textBanner: 'ბანერის ძირითადი ტექსტი',
			textBtn: 'ღილაკის ტექსტი',
			link: 'ჩასვით ბმული',
			startDate: 'დაწყების თარიღი',
			timeStart: 'დაწყების დრო',
			endDate: 'დასასრულის თარიღი',
			timeEnd: 'დასრულების დრო',
		},
		danger: {
			startDate: 'გამოქვეყნების თარიღი',
			endDate: 'პუბლიკაციის გამოქვეყნების დასრულების თარიღი',
		},
		activeMessage: 'აქტიური შეიძლება იყოს მხოლოდ ერთი ბანერი',
	},
	createTopicAdmin: {
		creating: 'კატეგორიის შექმნა',
		editing: 'კატეგორიის რედაქტირება',
		form: {
			name: 'კატეგორიის სახელწოდება',
			shared: 'ხელმისაწვდომია გამოქვეყნებისთვის',
		},
		placeholder: {
			name: 'ჩაწერეთ კატეგორიის სახელწოდება',
		},
	},
	createRegionAdmin: {
		creating: 'რეგიონის შექმნა',
		editing: 'რეგიონის რედაქტირება',
		form: {
			name: 'რეგიონის სახელწოდება',
			rawCountries: 'ქვეყნები',
		},
		placeholder: {
			name: 'შეიტანეთ რეგიონის სახელი',
		},
	},
	editPageAdmin: {
		title: 'გვერდის რედაქტირება',
		infoIcon: 'ატვირთეთ პიქტოგრამა გვერდისთვის',
		subtitle: 'ქვესათაური ლოკალურ ენაზე',
		subtitleEn: 'ქვესათაური ინგლისურ ენაზე',
		localTitle: 'სათაური ლოკალურ ენაზე',
		englishTitle: 'სათაური ინგლისურ ენაზე',
	},
	createDictionariesAdmin: {
		title: {
			trip_purpose: 'შექმენით მოგზაურობის მიზანი',
			currencies: 'ვალუტის შექმნა',
			transport: 'შექმენით ტრანსპორტის ტიპი',
			ticket: 'ბილეთების კლასის შექმნა',
			service: 'სერვისის შექმნა',
		},
		label: {
			trip_purpose: 'მოგზაურობის მიზანი',
			currencies: 'ვალუტა',
			transport: 'ტრანსპორტის ტიპი',
			ticket: 'ბილეთების კლასი',
			service: 'სერვისი',
		},
		placeholder: 'შეიყვანეთ სათაური',
		child: {
			transport: 'ბილეთების კლასი',
		},
	},
	editDictionariesAdmin: {
		title: {
			trip_purpose: 'მოგზაურობის დანიშნულების ადგილის რედაქტირება',
			currencies: 'ვალუტის რედაქტირება',
			transport: 'ტრანსპორტის ტიპის რედაქტირება',
			ticket: 'ბილეთების კლასის რედაქტირება',
			service: 'სერვისის რედაქტირება',
		},
	},
	createBudgetAdmin: {
		title: 'შექმენით ბიუჯეტი',
		subBudgetTitle: 'შექმენით ქვებიუჯეტი',
		budget: 'ბიუჯეტი',
		subBudget: 'ქვებიუჯეტი',
		budgetOwner: 'ბიუჯეტის მფლობელი',
		subBudgetOwner: 'ქვებიუჯეტის მფლობელი',
		budgetSubbudgetName: 'ბიუჯეტი / ქვებიუჯეტის სახელი',
		budgetName: 'ბიუჯეტი სახელი',
		subBudgetName: 'ქვებიუჯეტის სახელი',
		defaultBudget: 'ნაგულისხმევი ბიუჯეტი',
		defaultSubBudget: 'ნაგულისხმევი ქვებიუჯეტი',
		placeholder: {
			user: 'აირჩიეთ ბიუჯეტის მფლობელი',
			subBudgetOwner: 'აირჩიეთ ქვებიუჯეტის მფლობელი',
			name: 'შეიყვანეთ ბიუჯეტის სახელი',
			subName: 'შეიყვანეთ ქვებიუჯეტის დასახელება',
			year: 'აირჩიეთ წელი',
		},
		createBudget: 'შექმენით ბიუჯეტი',
		createSubBudget: 'შექმენით ქვებიუჯეტი',
		empty: 'ბიუჯეტი ჯერ არ შექმნილა',
		emptyByParams: 'მითითებულ პარამეტრებზე შედეგი ვერ მოიძებნა.',
	},
	editBudgetAdmin: {
		title: 'ბიუჯეტის რედაქტირება',
		subBudgetTitle: 'ქვებიუჯეტის რედაქტირება',
	},
	createCurrencyRate: {
		currencyRate: 'გაცვლითი კურსი',
		addRate: 'კურსის დამატება',
		placeholders: {
			date: 'აირჩიეთ თარიღი',
		},
		empty: 'ვალუტის კურსი ჯერ არ არის შექმნილი',
	},
	editDailyAllowance: {
		dailyAllowance: 'დღიური შემწეობა',
		dailyAllowanceSize: 'დღიური შემწეობა',
		travelDirection: 'მოგზაურობის მიმართულება',
		travelDirectionPlaceholder: 'შეიყვანეთ მოგზაურობის მიმართულება',
		create: 'შექმენით დღიური',
		creating: 'შექმენით დღიური',
    editing: 'დღიურის რედაქტირება',
	},
	budgetReport: {
		total: 'სულ',
		used: 'Გამოყენებული',
		remaining: 'დარჩენილი',
		owners: 'ბიუჯეტი / ქვებიუჯეტის მფლობელი',
		budget: 'ბიუჯეტი',
		sub_budget: 'ქვებიუჯეტი',
		noBudgets: 'ბიუჯეტი ჯერ არ შექმნილა',
		allDepartments: 'ყველა დეპარტამენტი',
		corporate: 'კორპორატიული',
		finance: 'ფინანსები',
		spent: 'გაატარა',
		amountPending: 'მომლოდინე თანხა ($).',
	},
	businessTrips: {
		faq: 'ფუნქციური სახელმძღვანელო {link}',
		faqText: '«Ბიზნეს მოგზაურობა»',
		transfer: 'Გადაცემა',
		route: 'მარშრუტი',
		created: 'Შექმნილია მიერ',
		title: 'Ბიზნეს მოგზაურობა',
		allTrips: 'ყველა აპლიკაცია',
		daysCount: 'Დღეების რაოდენობა',
		myTrips: 'ჩემი აპლიკაციები',
		trip: 'განაცხადი',
		trips: 'აპლიკაციები',
		approved: 'დამტკიცებულია',
		inProcessing: 'Პროცესში',
		completed: 'დასრულებული',
		budgets: 'ბიუჯეტები',
		reportsByEmployee: 'თანამშრომელი იუწყება',
		number: 'ნომერი',
		status: 'სტატუსი',
		employeeOrNot: 'თანამშრომელი / კონსულტანტი',
		tripPurpose: 'მოგზაურობის მიზანი',
		destination: 'დანიშნულება',
		startDateToEndDate: 'დაწყების / დასრულების თარიღი',
		plan: 'Გეგმა',
		fact: 'ფაქტი',
		planSum: 'თანხის ({currencySymbol}) გეგმა',
		factSum: 'თანხის ({currencySymbol}) ფაქტი',
		filters: {
			startDate: 'Დაწყების თარიღი',
			endDate: 'ვადის გასვლის თარიღი',
			createdDate: 'შექმნის თარიღი',
		},
		deleteReason: 'წაშლის მიზეზი',
		roleStatuses: {
			approved: 'განაცხადი დამტკიცებულია',
			pending: 'განაცხადი დამტკიცებისთვის',
			rejected: 'განაცხადი უარყოფილია',
		},
		factSpent: 'რეალური ხარჯები',
		pinnedEmployees: 'დანიშნული თანამშრომლები',
		viewFrom: 'თქვენ ათვალიერებთ გვერდს საიდან',
		fromAllBudgetOwners: 'ყველა ბიუჯეტის მფლობელი',
		inputAccountantExpensesReason: 'შეიყვანეთ რეალური ხარჯების გადაჭარბების მიზეზი',
	},
	createBusinessTrip: {
		title: 'შექმენით საქმიანი მოგზაურობა',
		employee: 'თანამშრომელი',
		notEmployee: 'კონსულტანტი',
		fio: 'გვარი და სახელი',
		startBusinessTrip: 'საქმიანი მოგზაურობის დაწყება',
		endBusinessTrip: 'მივლინების დასასრული',
		dayCounts: 'Დღეების რაოდენობა: {count}',
		additional: 'დამატებით',
		transferAirport: 'ტრანსფერი აეროპორტიდან / აეროპორტამდე',
		transferOffice: 'ტრანსფერი ოფისში ან სხვა პუნქტში',
		datePlaceTime: 'თარიღი, ადგილი და დრო',
		transportType: 'ტრანსპორტის ტიპი',
		cityAndCountry: 'Ქალაქი სოფელი',
		country: 'ქვეყანა',
		city: 'ქალაქი',
		placeholder: {
			fio: 'შეიყვანეთ სახელი და გვარი',
			user: 'აირჩიეთ თანამშრომელი',
			transportType: 'აირჩიეთ ტრანსპორტის ტიპი',
			ticketType: 'აირჩიეთ ბილეთის კლასი',
			additional: 'აირჩიეთ დამატებითი სერვისები',
			transferAirport: 'მიუთითეთ თარიღი, ადგილი და დრო',
			otherInfo: 'მოგზაურობის სურვილები',
		},
		errors: {
			cantCreateTrip: 'მივლინების შექმნა შეუძლებელია',
			notFoundRoleAndBudgetCount: 'არცერთი ბიზნეს ერთეული არ არის მინიჭებული თქვენს ბიზნესზე {roles} და ბიუჯეტი არ იყო გამოყოფილი. გთხოვთ, დაუკავშირდეთ თქვენს ხელმძღვანელს.',
			not_found_role: 'არცერთი ბიზნეს ერთეული არ არის მინიჭებული თქვენს ბიზნესზე {roles}. გთხოვთ, დაუკავშირდეთ თქვენს ხელმძღვანელს.',
			budget_count: 'ბიუჯეტი არ არის გამოყოფილი თქვენი ბიზნეს ერთეულისთვის. გთხოვთ, დაუკავშირდეთ თქვენს ხელმძღვანელს.',
		},
		empty: 'ჯერ არ გქონიათ მივლინებები',
		emptyDesc: 'შექმენით მივლინების მოთხოვნა',
		arrange: 'გადახდა',
		successGoToApprove: 'განაცხადის ნომერი {number} გაიგზავნა დასამუშავებლად.',
		successGoToReApprove: 'განაცხადის ნომერი  {number} გაიგზავნა ხელახლა დასამტკიცებლად.',
		successClosed: 'განაცხადის ნომერი {number} დახურულია.'
	},
	editBusinessTrip: {
		title: 'მოგზაურობის რედაქტირება',
		request: 'განაცხადი {number}',
		budgetOwnerForApprove: 'ბიუჯეტი / ქვებიუჯეტის მფლობელი',
		budget: 'ბიუჯეტი / ქვებიუჯეტის მფლობელი',
		hotelPrice: 'ცხოვრების ღირებულება',
		transportPrice: 'ტრანსპორტირების ღირებულება',
		transferPrice: 'გადარიცხვის ღირებულება',
		dailyPayment: 'დღიური შემწეობა',
		otherExpenses: 'სხვა',
		otherExpensesDestination: 'თანხის მინიჭება "სხვა"',
		total: 'სულ',
		placeholder: {
			budgetOwnerForApprove: 'აირჩიეთ სიიდან',
			budget: 'აირჩიეთ სიიდან',
		},
		reApproval: 'ხელახალი დამტკიცება',
		reApprovalDesc: 'გსურთ ხელახალი დამტკიცების განაცხადის გაგზავნა?',
		wishes: 'სურვილები',
		accommodation: 'განთავსება',
		transport: 'ტრანსპორტი',
		transfer: 'ადაცემა',
		uploadDocumentsTickets: 'ატვირთეთ დოკუმენტები, ბილეთები'
	},
	businessTripStatuses: {
		created: 'Პროცესში',
		wait_approval: 'Დამტკიცების მოლოდინში',
		approved: 'დამტკიცებულია',
		partially_approved: 'ნაწილობრივ დამტკიცებულია',
		completed: 'ჩარჩოში ჩასმული',
		closed: 'დახურულია',
		decline: 'უარყოფილია',
	},
	profile: {
		information: 'ინფორმაცია',
		articlesMy: 'ჩემი პუბლიკაციები',
		articles: 'პუბლიკაციები',
		birthday: 'დაბადების დღე',
		birthdaysModal: 'დაბადების დღეები',
		country: 'ქვეყანა',
		city: 'ქალაქი',
		businessUnit: 'ბიზნეს-ერთეული',
		company: 'კომპანია',
		socialProfiles: 'სოციალური პროფილი',
		contactInfo: 'საკონტაქტო ინფორმაცია',
		phone: 'ტელეფონი',
		mail: 'ლექტრონული მისამართი',
		workAddress: 'სამუშაო ადგილის მისამართი',
		career: 'კარიერა',
		joinedToTeam: 'შეუერთდა IDS BORJOMI გუნდს',
		leader: 'ხელმძღვანელი',
		personalInfo: 'პირადი ინფორმაცია',
		language: 'ენების ფლობა',
		certificationsAndSkills: 'სერტიფიკატები და დამატებით უნარები',
		workExperience: 'სამუშაო გამოცდილება IDS BORJOMI-მდე',
		hobbies: 'გატაცება',
		functional: 'ფუნქციონალი',
		removedText: 'ამ მომენტში ეს თანამშრომელი კომპანიაში არ მუშაობს',
	},
	profileSettings: {
		title: 'პროფილის პარამეტრები',
		mainInformation: 'ძირითადი ინფორმაცია',
		socialProfiles: 'სოციალური პროფილი',
		alerts: 'შეტყობინება',
		upload: 'ფოტოს ატვირთვა',
		receiveAlerts: 'შეტყობინების მიღება ელექტრონულ ფოსტაზე',
		form: {
			avatar: 'ფოტო',
			workAddress: 'სამუშაო ადგილის მისამართი',
			languages: 'ენების ფლობა',
			additionalSkills: 'სერტიფიკატები და დამატებითი უნარები',
			prevExperience: 'სამუშაო გამოცდილება IDS BORJOMI-მდე',
			hobbies: 'გატაცება',
			social: 'სოც. ქსეელი',
			link: 'ბმული',
			functional: 'ფუნქციონალი',
			locale: 'პორტალის ენა',
			receivePublicationMessage: 'პასუხი ჩემს პუბლიკაციებზე',
			receiveBirthdayMessage: 'დაბადების დღის მილოცვა',
		},
		placeholder: {
			workAddress: 'მიუთითეთ მისამართი',
			languages: 'ენები',
			additionalSkills: 'მითითებები სერტიფიკატებსა და უნარებზე',
			prevExperience: 'სამუშაო გამოცდილება',
			hobbies: 'მიუთითეთ თქვენი გატაცებები',
			social: 'აირჩიეთ სოც. ქსელი',
			link: 'ჩასვით ბმული',
			functional: 'აღწერეთ თქვენი მოვალეობები',
		},
	},
	staffSearch: {
		name: 'სახელი',
		phone: 'ტელეფონი',
		email: 'ელ. ფოსტა',
	},
	search: {
		title: 'ძებნის შედეგი',
		desc: 'საძებნ სიტყვაზე <span class="font-weight-bold">{search}</span> მოიძებნა <span class="font-weight-bold">{count}</span> შედეგი',
		articles: 'სიახლეები',
		staff: 'თანამშრომლები',
		medias: 'დოკუმენტები',
		events: 'ღონისძიებები',
		empty: {
			title: 'ძებნა უშედეგოა',
			desc: 'სცადეთ სხვა საკვანძო სიტყვა',
		},
	},
	auth: {
		auth: 'ავტორიზაცია',
		authDesc: 'პორტალზე შესასვლელად შეავსეთ ქვემოთ მოცემული ველები.',
		authConfirm: 'შეამოწმეთ ელ. ფოსტა',
		authConfirmDesc: 'ახლახან გამოგიგზავნეთ ექვსნიშნა კოდი მისამართზე <span class="font-weight-boldest">{email}</span>. არ დახუროთ ეს ფანჯარა, შეამოწმეთ ელ. ფოსტა და შეიტანეთ კოდი.',
		form: {
			email: 'ლექტრონული მისამართი',
			password: 'პაროლი',
			code: 'კოდი',
		},
		iAccept: 'ვეთანხმები',
		termsOfUseOfThePortal: 'პორტალის გამოყენების წესები',
		signIn: 'შესვლა',
		didtReceiveLetter: 'წერილი არ მოვიდა?',
		sendCodeAgain: 'ახალი კოდის მოთხოვნა',
		codeSent: 'თქვენი კოდი გამოიგზავნა განმეორებით.',
		error: {
			invalidCredentials: 'ლოგინი ან პაროლი არასწორია',
			blockAccaount: 'თქვენი ანგარიში დაბლოკილია. აღსადგენად მიმართეთ ადმინს',
			accountUnlock: 'ბლოკი მოხსნილია',
			invalidCode: 'კოდი არასწორია. სცადეთ ხელახლა.',
		},
	},
	validation: {
		invalid: '{name} მიუწვდომელია',
		required: '{name} სავალდებულო ველი',
		requiredIf: '{name} სავალდებულო ველი',
		greaterThan: 'მეტი უნდა იყოს {value}',
		lessThan: 'მაქსიმალური {value}',
		requiredDays: 'აირჩიეთ დღეები განმეორების დასაყენებლად',
		alphaNum: '{name} ივსება ინგლისურ ენაზე, სიტყვათშორისი სივრცის გარეშე',
		alphaNumCustom: '{name} ივსება ინგლისურ ენაზე, სიტყვათშორისი სივრცის გარეშე',
		variant: 'სავალდებულო ველი',
		url: '{name} უნდა იყოს url მისამართი',
		imageFormat: 'ფაილის არასწორი ფორმატი. დასაშვები ფორმატი: {format}',
		imageWeight: 'ფაილი ძალიან დიდია. მაქსიმალური ზომა: {weight} MB',
		imageSize: 'ფაილის არასწორი ზომა. მინიმალური ზომა: {size}',
		imageMaxCount: 'ასატვირთი ფაილების მაქსიმალური რაოდენობა - {count}',
		fileNameMaxCount: 'ფაილის სახელის სიმბოლოების მაქსიმალური რაოდენობაა {count}',
		fileMinWeight: 'ფაილის მინიმალური ზომა: {size} კბ',
		someFileAlreadyExists: 'სისტემაში უკვე არსებობს ერთ-ერთი ფაილი',
		isAfter: '{name} დაწყების თარიღის შემდეგ',
		maxLength: 'სიმბოლოების მაქსიმალური რაოდენობაა {count}',
		maxValue: 'მაქსიმალური ღირებულება - {val}',
		fileType: 'ატვირთული ფაილის ფორმატი დაუშვებელია',
	},
	quiz: {
		quiz: 'კითხვარი',
		startQuiz: 'გამოკითხვა',
		selected: 'პასუხი არჩეულია',
		results: 'გამოკითხვის შედეგები',
		variant: 'ერთ-ერთი ვარიანტი',
		publicResults: 'შედეგები',
		voting: 'ხმის მიცემა',
		activeMessage: 'აქტიური შეიძლება იყოს მხოლოდ ერთი კითხვარი',
		thankYou: 'მადლობა გამოკითხვაში მონაწილეობისთვის',
		statuses: {
			active: 'აქტიური',
			notActive: 'არააქტიური',
		},
		peopleVote: 'ხმა მისცა 0 ადამიანმა | ხმა მისცა {n} ადამიანმა | ხმა მისცა {n} ადამიანმა | ხმა მისცა {n} ადამიანმა ',
		peopleSelected: 'არჩეულია 0 ადამიანი | არჩეულია  {n} ადამიანი | არჩეულია  {n} ადამიანი | არჩეულია  {n} ადამიანი ',
	},
	calendar: {
		birthdayDisabledText: 'ამ მომხმარებელს აქვს შეზღუდვები მისალმების გაგზავნაზე',
		toEvents: 'ღონისძიებები',
		eventText: 'ღონისძიებები',
		createEvent: 'ღონისძიების შექმნა',
		editEvent: 'ღონისძიების რედაქტირება',
		removeEvent: 'წაშლის დადასტურება',
		removeEventAlert: 'ღონისძიება წაშლილია',
		birthdays: 'დაბადების თარიღი',
		units: 'ბიზნეს-ერთეული',
		year: 'წელი',
		month: 'თვე',
		week: 'კვირა',
		day: 'დღე',
		today: 'დღეს',
		none: 'არ მეორდება',
		typeEvent: 'ღონისძიება',
		typeInfo: 'საინფორმაციო',
		showAllUsers: 'ყველა მონაწილის ჩვენება',
		participating: 'მონაწილეობთ?',
		wantToJoin: 'გსურთ მონაწილეობის მიღება?',
		successJoinMessage: 'მოთხოვნა მონაწილეობაზე გაეგზავნა ორგანიზატორს',
		allParticipants: 'ყველა მონაწილე',
		participant: '0 მონაწილე| {n} მონაწილე| არჩეულია {n} მონაწილე| არჩეულია {n} მონაწილე',
		// birthdayMan: '0 იუბილარი | {n} იუბილარი | არჩეულია {n} იუბილარი | Выбрал {n} იუბილარი',
		birthdayMan: '0 იუბილარი | {n} იუბილარი | {n} იუბილარი | {n} იუბილარი',
		inputCongratulation: 'შეიტანეთ მილოცვა',
		repeatAt: 'გამეორება',
		onSuchDays: 'ასეთ დღეებში:',
		congratulationWasSend: 'მილოცვა გაგზავნილია!',
		event: {
			title: {
				label: 'ღონისძიების სახელწოდება',
				placeholder: 'ღონისძიების სახელწოდება',
			},
			startDate: {
				label: 'ღონისძიების დაწყების თარიღი',
			},
			endDate: {
				label: 'ღონისძიების დასრულების თარიღი',
			},
			periodicity: {
				label: 'პერიოდულობა',
			},
			type: {
				label: 'ღონისძიების ტიპი',
			},
			link: {
				label: 'ღონისძიების ბმული',
			},
			users: {
				label: 'მონაწილეები',
			},
			invited: {
				label: 'მოწვეულები',
			},
			description: {
				label: 'აღწერა',
				placeholder: 'ღონისძიების აღწერა',
			},
		},
		days: {
			monday: 'ორშ',
			tuesday: 'სამშ',
			wednesday: 'ოთხ',
			thursday: 'ხუთ',
			friday: 'პარ',
			saturday: 'შაბ',
			sunday: 'კვ',
		},
		finish: 'დასრულება',
		repeatSettings: 'გამეორების პარამეტრები',
		organizer: 'ორგანიზატორი',
	},
	ie: {
		header: 'სამწუხაროდ თქვენი ბრაუზერი შეუსაბამოა',
		text: 'IDS Borjomi ინტერნეტ-პორტალზე შესასვლელად გამოიყენეთ სხვა ბრაუზერი.',
	},
	knowledgeBase: {
		folderBase: 'საქაღალდე',
		fileBase: 'ფაილი',
		createFolder: 'საქაღალდის შექმნა',
		editFolder: 'საქაღალდის რედაქტირება',
		toCreateFolder: 'საქაღალდის შექმნა',
		toEditFolder: 'საქაღალდის რედაქტირება',
		title: 'სათაური',
		author: 'ავტორი',
		createdAt: 'შექმნის თარიღი',
		size: 'ზომა',
		folder: {
			name: {
				label: 'საქაღალდის სახელი',
				placeholder: 'შეიტანეთ საქაღალდის სახელი',
			},
			description: {
				label: 'აღწერა',
				placeholder: 'შეიტანეთ საქაღალდის აღწერა',
			},
			author: 'ავტორი',
			createdAt: 'შექმნის თარიღი',
			size: 'ზომა',
		},
		emptyRootTitle: 'გვერდი "დოკუმენტები" ცარიელია',
		emptyRootDescription: 'საქაღალდის შექმნა',
		emptyTitle: 'ეს საქაღალდე ცარიელია',
		emptyDescription: 'ასატვირთად გადმოათრიეთ საქაღალდე ამ ფანჯარაში.',
		uploadIcon: 'ატვირთეთ პიქტოგრამა საქაღალდისთვის',
	},
	bookmarks: {
		myLinks: 'ჩემი ბმულები',
		addLink: 'ბმულის დამატება',
		editLink: 'ბმულის რედაქტირება',
		toAddLink: 'ბმულის დამატება',
		link: {
			title: {
				label: 'ბმულის სახელი',
				placeholder: 'შეიტანეთ ბმულის სახელი',
			},
			url: {
				label: 'URL - მისამართი',
				placeholder: 'http://',
			},
		},
	},
	policy: {
		h1: 'პორტალის გამოყენების წესები',
		p1: 'ძვირფასო კოლეგავ, მოგესალმებით',
		p1_1: 'ინტრანეტ პორტალზე! ',
		p2: 'აქ ჩვენ ყველანი IDS Borjomi -ს თანამშრომლები ვართ, შეგვიძლია გავიგოთ ყველაფერი ჩვენი ჯგუფისა და მისი სტრატეგიის შესახებ, გავუზიაროთ ერთმანეთს სიახლეები,მოვიპოვოთ წვდომა აქტუალურ ინფორმაციასა და სერვისებზე, რომლებიც გვეხმარება მუშაობაში.',
		p3: 'პორტალზე შესვლით, შენ თანხმობას აცხადებ დაიცვა შემდეგი წესები:',
		li1: 'დაიცვა ურთიერთობის ეთიკა და პატივი სცე სხვის აზრს;',
		li2: 'დატოვო პორტალს მიღმა მწვავე სოციალური თემების განხილვა, პირადი საკითხების, ფულისა და ჯანმრთელობის ჩათვლით;',
		li3: 'მისდიო გამჭვირვალეობის პრინციპს და ინფორმაციის გამოქვეყნებისას არ მიმართო ანონიმურობას;',
		li4: 'გახსოვდეს ის, რომ პორტალი განკუთვნილია მხოლოდ თანამშრომლების შიდა გამოყენებისთვის, ანუ შინაარსი არის კონფიდენციალური.',
		li5: 'წერო გრამატიკულად გამართული ენით;',
		li6: 'იყო მზად იმისთვის, რომ პორტალის ადმინისტრაციამ შეიძლება უარი გითხრას გამოქვეყნებაზე. ან წაშალოს კონტენტი პორტალზე, თუ იგი არღვევს ნებისმიერ გახმოვანებულ წესს.',
		p4: '',
		p4_1: ' -ზე მიუღებელია',
		li7: 'არანორმატიული ლექსიკის, უხეში ფრაზების გამოყენება, პიროვნების შეურაცხყოფა, ეროვნული, რელიგიური და ზნეობრივი გრძნობების შელახვა;',
		li8: 'წინასწარი ცნობით ცრუ ინფორმაციის გამოქვეყნება;',
		li9: 'არალიცენზიურ კომერციულ პროგრამულ უზრუნველყოფაზე ბმულების, მისი გატეხვის პროგრამების და გასაღებების გენერატორების გამოქვეყნება  ასევე, საავტორო უფლებებით დაცული მასალების გამოქვეყნება;',
		li10: 'მოდერატორის როლში გამოსვლა: შენიშვნების გაკეთება სხვა მომხმარებლებისთვის შესაბამისი უფლებამოსილებების გარეშე;',
		li11: 'მომხმარებლების, მოდერატორებისა და ადმინისტრაციის  მოქმედებების განხილვა და განსჯა.',
		p5: 'წესების დარღვევისთვის ადმინისტრატორებმა შეიძლება მოახდინონ გაფრთხილება და დაბლოკონ ანგარიში, მაგრამ ჩვენ ამას არ ვისურვებდით.',
		p6: 'ყველა ტექნიკურ საკითხებზე მიმართეთ IT Service Desk',
		p7: 'კონტენტის საკითხებზე Ask IDS Borjomi',
		p8: 'დაე ',
		p8_1: '-ს მოჰქონდეს მხოლოდ სარგებელი მუშაობასა და კომუნიკაციებში!',
		p9: 'შიდა კომუნიკაციების გუნდი',
	},
};

export default locale;
