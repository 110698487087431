import Vue from 'vue'
import Vuex from 'vuex'

import base from './base.module';
import auth from './auth.module';
import knowledgebase from './knowledgebase.module';
import ckeditor from './ckeditor.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    base,
    knowledgebase,
    ckeditor,
  },
})
