export default [
    {
        path: "/business-trips",
        name: "viewBusinessTrips",
        meta: { title: 'viewBusinessTrips' },
        component: () => import('@/pages/business-trips'),
    },
    {
        path: "/business-trips/create",
        name: "createBusinessTrip",
        meta: { title: 'createBusinessTrip' },
        component: () => import('@/pages/business-trips/create'),
    },
    {
        path: "/business-trips/:id/edit",
        name: "editBusinessTrip",
        meta: { title: 'editBusinessTrip' },
        component: () => import('@/pages/business-trips/_id/edit'),
    },
    {
        path: "/business-trips/:id/cost",
        name: "costBusinessTrip",
        meta: { title: 'editBusinessTrip' },
        component: () => import('@/pages/business-trips/_id/cost'),
    },
    {
        path: "/business-trips/:id/edit-costed-trip",
        name: "editCostedBusinessTrip",
        meta: { title: 'editBusinessTrip' },
        component: () => import('@/pages/business-trips/_id/edit-costed-trip'),
    },
    {
        path: "/business-trips/:id/edit-approved-trip",
        name: "editApprovedBusinessTrip",
        meta: { title: 'editBusinessTrip' },
        component: () => import('@/pages/business-trips/_id/edit-approved-trip'),
    },
]